/// <reference types="./onedrive.d.ts" />

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Attachment, AttachmentBlobUrls, RcgFieldType, RcgFormlyFieldProps } from '@rcg/core/models';
import { AttachmentsService, OneDriveAttachmentApp } from '@rcg/core/services';
import { isMobile } from 'is-mobile';
import { Subscription, firstValueFrom, interval, skipWhile, startWith } from 'rxjs';
import { FormDialogService } from '../../services/form-dialog.service';

interface AttachmentsFieldProps {
  multiple?: boolean;
}

interface AttachmentsFieldSettings {
  fieldKey?: string;
  sharedAttachmentsFieldName?: string;
  relations: {
    fkId?: string;
  };
  hideFileUpload?: boolean;
  displayInline?: boolean;
}

@Component({
  selector: 'rcg-attachments-field',
  templateUrl: './attachments-field.component.html',
  styleUrls: ['./attachments-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsFieldComponent
  extends RcgFieldType<number[] | undefined, RcgFormlyFieldProps<AttachmentsFieldProps, AttachmentsFieldSettings>>
  implements OnInit, OnDestroy
{
  public readonly isMobile = isMobile({ tablet: true, featureDetect: true });

  constructor(
    private attachmentService: AttachmentsService,
    private changeRef: ChangeDetectorRef,
    private formDialogService: FormDialogService,
  ) {
    super();
  }
  private attachmentsSubscription?: Subscription;
  @ViewChild('fileUpload') fileUpload?: ElementRef;

  attachments: Attachment[] = [];
  fileNames: string[] = [];
  blobUrls: AttachmentBlobUrls = {};

  public oneDriveAttachmentApp$ = this.attachmentService.oneDriveAttachmentApp$;

  get formCtrl() {
    return this.formControl;
  }

  get isDisabledOrReadonly(): boolean {
    return this.props?.readonly === true || this.props?.disabled === true;
  }

  get multiple(): boolean {
    return !!(this.props?.multiple ?? true);
  }

  get initialValue(): number[] {
    const fieldKey = this.props?.settings?.fieldKey?.trim();
    const sharedAttachmentsName = this.props?.settings?.sharedAttachmentsFieldName?.trim();
    if (fieldKey && sharedAttachmentsName) {
      // read attachments from shared attachments model value, not from field value if field_key is set on field settings and sharedAttachmentsFieldName set
      // usually means that form has multiple attachments fields with same relation. field_key value is this.field.key name
      // sharedAttachmentsFieldName is the name of shared attachemnts relation on hasura
      const attachValues: Record<string, unknown>[] | undefined | null = this.model[sharedAttachmentsName];
      if (!attachValues || attachValues.length === 0) return [];

      const idField = this.props?.settings?.relations?.fkId;
      return attachValues
        .filter((v) => v[fieldKey] === this.field.key)
        .map((e) => (e.inline ? -(e[idField!] as number) : (e[idField!] as number)));
    }

    // read attachment value from field value - withoud field_key
    return this.value?.length
      ? this.value.map((val) => {
          const obj = val as unknown as { attachment_id: number; inline?: boolean };
          return obj.inline ? -obj.attachment_id : obj.attachment_id;
        })
      : [];
  }

  ngOnInit(): void {
    this.onChange(this.initialValue, false);
    this.subscribeToAttachments();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.attachmentsSubscription?.unsubscribe();
  }

  onThumbnailClicked(attachment: Attachment) {
    this.formDialogService.openAttachmentsViewer({
      selectedAttachment: attachment,
      attachments: this.attachments,
      thumbnailBlobUrls: this.blobUrls,
      canDelete: !this.isDisabledOrReadonly,
      deleteAttachment: this.deletAttachment.bind(this),
    });
  }

  async onFilesSelected(event: Event) {
    try {
      const target = event.target as HTMLInputElement;
      const files = Array.from(target?.files ?? []);
      if (!files || files.length === 0) {
        return;
      }
      const attachmentIds = await this.attachmentService.uploadAttachments(files);
      this.onChange([...(this.value ?? []), ...attachmentIds]);
    } catch (error) {
      console.error('Attachment field - Upload attachment error', error);
    }
  }

  private subscribeToAttachments() {
    this.attachmentsSubscription = this.attachmentService.makeAttachmentSubscription({
      attachmentIds$: this.formCtrl.valueChanges.pipe(startWith(this.value)),
      getAttachmentsForDownload: (att) => this.getAttachmentsForDownload(att),
      addBlobUrls: (blobUrls) => (this.blobUrls = { ...this.blobUrls, ...blobUrls }),
      setAttachments: (attachments) => {
        (this.attachments = [...attachments]), this.changeRef.markForCheck();
      },
    });
  }

  private onChange(attachmentIds: number[], markAsDrty = true) {
    this.value = [...attachmentIds];
    if (markAsDrty && this.formCtrl.pristine) {
      this.formCtrl.markAsDirty();
    }
  }

  private getAttachmentsForDownload(attachments: Attachment[]): Attachment[] {
    if (attachments.length === 0) {
      return [];
    }
    return attachments.filter((a) => {
      if (this.blobUrls[a.id]) {
        return false;
      }
      return true;
    });
  }

  private deletAttachment(attachment: Attachment) {
    if (this.isDisabledOrReadonly) return;

    this.deleteBlobUri(attachment.id);
    const newAttachments = this.attachments.filter((a) => a.id !== attachment.id);
    this.attachments = [...newAttachments];
    this.onChange(newAttachments.map((a) => (a.inline ? -a.id : a.id)));
  }

  private deleteBlobUri(attachmentId: number) {
    if (this.blobUrls[attachmentId]) {
      delete this.blobUrls[attachmentId];
    }
  }

  uploadClick(event: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    if (this.fileUpload?.nativeElement) {
      this.fileUpload.nativeElement?.click();
    }
  }

  async loadOneDriveScript(tenantId: string) {
    const oldScriptEl = document.head.querySelector('script.oneDrive');

    localStorage.setItem('rcgNgOneDriveTenantId', tenantId);

    if (!globalThis.OneDrive || !oldScriptEl || oldScriptEl.getAttribute('tenantId') !== tenantId) {
      if (oldScriptEl) oldScriptEl.remove();

      const scriptEl = document.createElement('script');
      scriptEl.classList.add('oneDrive');
      scriptEl.setAttribute('tenantId', tenantId);
      scriptEl.src = '/onedrive/OneDrive.Debug.js';
      document.head.appendChild(scriptEl);
    }

    await firstValueFrom(
      interval(200).pipe(
        startWith(),
        skipWhile(() => !globalThis.OneDrive),
      ),
    );
  }

  async oneDriveDownloadClick(event: MouseEvent, app: OneDriveAttachmentApp) {
    event.preventDefault();
    event.stopPropagation();

    await this.loadOneDriveScript(app.tenant_id);

    OneDrive.open({
      clientId: app.client_id,
      action: 'download',
      multiSelect: this.multiple,
      advanced: {
        redirectUri: app.redirect_uri,
      },
      success: async (response) => {
        try {
          const urls = response.value.map((v) => v['@microsoft.graph.downloadUrl']);

          const attachmentIds = await this.attachmentService.importAttachments(urls);
          this.onChange([...(this.value ?? []), ...attachmentIds]);
        } catch (error) {
          console.error('Attachment field - OneDrive attachment import error', error);
        }
      },
      error: (error) => {
        console.error('Attachment field - OneDrive attachment select error', error);
      },
    });
  }
}
