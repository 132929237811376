import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewChild, signal } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RcgFieldType } from '@rcg/core/models';
import { SignatureComponent } from '@syncfusion/ej2-angular-inputs';

type SignatureState = 'loading' | 'error' | 'noSignature' | 'drawing' | 'signed';
@Component({
  selector: 'rcg-signature-field',
  templateUrl: './signature-field.component.html',
  styleUrls: ['./signature-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureFieldComponent extends RcgFieldType implements OnInit {
  @ViewChild('signature') signature?: SignatureComponent;

  @HostBinding('class.floating')
  override get shouldLabelFloat() {
    return true;
  }

  get signatureValue() {
    return this.value as string | undefined;
  }
  private readonly base64DataPrefix = 'data:image/jpg;base64,';

  showSignatureOnNotSigned: boolean = false;

  height?: string;

  width?: string;

  maxStrokeWidth?: number | undefined;

  minStrokeWidth?: number | undefined;

  velocity?: number | undefined;

  state = signal<SignatureState>('loading');

  error = signal<string | null>(null);

  isDisabled = false;

  get isReadonly(): boolean {
    return this.props.readonly === true || this.disabled === true;
  }

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit() {
    this.showSignatureOnNotSigned = this.props.settings?.showSignatureOnNotSigned === true;
    this.height = this.props.settings?.height as string | undefined;
    this.width = this.props.settings?.width as string | undefined;
    this.velocity = this.props.settings?.velocity as number | undefined;
    this.minStrokeWidth = this.props.settings?.minStrokeWidth as number | undefined;
    this.maxStrokeWidth = this.props.settings?.maxStrokeWidth as number | undefined;

    try {
      this.error.set(null);
      const signatureBase64 = (this.value as string | undefined)?.trim();
      if (signatureBase64) {
        this.value = this.base64DataPrefix + signatureBase64;
        this.setState('signed');
        return;
      }
      if (this.showSignatureOnNotSigned === true) {
        this.setState('drawing');
      } else {
        this.setState('noSignature');
      }
    } catch (error) {
      console.error('Signature - convert base64 string to image', error);
      this.error.set(`Napaka inicializacije podpisa: ${error}`);
      this.setState('error');
    }
  }

  clearSignature() {
    try {
      this.signature!.clear();
      this.changeValue('');
    } catch (error) {
      console.error('Signature - clear', error);
    }
  }

  onSignatureChange() {
    try {
      this.changeValue((this.signature?.getSignature() ?? '').replace(/^data:image\/\w+;base64,/, ''));
    } catch (error) {
      console.error('Signature - onChange', error);
    }
  }

  newSignature() {
    this.changeValue('');
    this.setState('drawing');
  }

  private setState(state: SignatureState) {
    this.state.set(state);
  }

  private changeValue(value: string | undefined | null) {
    this.value = value;
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }
}
