import { FormlyFieldConfig } from '@ngx-formly/core';
import { tr } from '@rcg/intl';
import { map } from 'rxjs';

export const propsFormFields: FormlyFieldConfig[] = [
  {
    key: 'digits',
    type: 'select',
    defaultValue: 6,
    props: {
      options: [
        {
          label: '6',
          value: 6,
        },
        {
          label: '7',
          value: 7,
        },
        {
          label: '8',
          value: 8,
        },
      ],
    },
    expressions: {
      'props.disabled': 'model.loading',
      'props.label': tr('mfa_totp_prop_digits'),
      'props.options[0].label': tr('default').pipe(map((d) => `6 (${d})`)),
    },
  },
  {
    key: 'digest',
    type: 'select',
    defaultValue: 'SHA-1',
    props: {
      options: [
        {
          label: 'SHA-1',
          value: 'SHA-1',
        },
        {
          label: 'SHA-256',
          value: 'SHA-256',
        },
        {
          label: 'SHA-512',
          value: 'SHA-512',
        },
      ],
    },
    expressions: {
      'props.disabled': 'model.loading',
      'props.label': tr('mfa_totp_prop_algorithm'),
      'props.options[0].label': tr('default').pipe(map((d) => `SHA-1 (${d})`)),
    },
  },
  {
    key: 'period',
    type: 'select',
    defaultValue: 30,
    props: {
      options: [
        {
          label: '15s',
          value: 15,
        },
        {
          label: '30s',
          value: 30,
        },
        {
          label: '60s',
          value: 60,
        },
      ],
    },
    expressions: {
      'props.disabled': 'model.loading',
      'props.label': tr('mfa_totp_prop_period'),
      'props.options[1].label': tr('default').pipe(map((d) => `30s (${d})`)),
    },
  },
];
