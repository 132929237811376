import { Injectable } from '@angular/core';
import { AuthService } from '@rcg/auth';
import { DescriptionSetting, SelectColor, SelectOption } from '@rcg/core/models';
import { GraphqlClientService } from '@rcg/graphql';
import { gql } from 'apollo-angular';
import * as dot from 'dot-object';
import { Observable, firstValueFrom, map } from 'rxjs';
import { FormDialogService } from '../../../services/form-dialog.service';
import { extractFieldData, getFieldValueFromPath, getNestedFieldData } from '../../../utils/field-path-utils';
import { SupportedForm } from '../../../utils/supported-forms-utils';
import { ACSettings, AcTreeSettings, AddNewContactSettings } from '../models/ac-settings';

@Injectable()
export class AutocompleteService {
  constructor(private gqlClient: GraphqlClientService, private auth: AuthService, private formDialogService: FormDialogService) {}

  getAutoCompleteTreeData(acSettings: AcTreeSettings, model: Record<string, unknown>, search?: string): Observable<SelectOption[]> {
    const searchTerm = search?.trim() ?? '';

    let variables = {
      search: `${acSettings.searchSettings?.prefix ?? ''}${searchTerm}${acSettings.searchSettings?.suffix ?? ''}`,
    };

    if (acSettings.gqlVariablesFromModel && Object.keys(acSettings.gqlVariablesFromModel).length > 0) {
      const modelVars: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(acSettings.gqlVariablesFromModel)) {
        modelVars[key] = getNestedFieldData(model, value ?? null);
      }
      variables = {
        ...variables,
        ...modelVars,
      };
    }

    return this.gqlClient
      .query<{ data?: unknown[] }>({
        query: gql(acSettings.query),
        variables: variables,
      })
      .pipe(
        map((result) => {
          const treeData = this.processTreeNestedData(
            result?.data ?? ([] as unknown[]),
            acSettings.additionalDataFields,
            acSettings.descriptionSetting,
            3,
          );
          return treeData;
        }),
      );
  }

  private processTreeNestedData(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[],
    additionalDataFields: string[] | undefined,
    descriptionSetting: (string | { literal: string })[] | ((value: Record<string, unknown>) => string | DescriptionSetting[]),
    n: number,
  ): SelectOption[] {
    return data
      .map((item) => {
        const additionalData: Record<string, unknown> = {};

        if (additionalDataFields && additionalDataFields.length > 0) {
          for (const name of additionalDataFields) {
            additionalData[name] = item[name];
          }
        }

        const description = this.getDescription(descriptionSetting, item);

        let children: SelectOption[] = [];
        if (n > 0 && item.children && item.children.length > 0) {
          children = this.processTreeNestedData(item.children, additionalDataFields, descriptionSetting, n - 1);
        }

        return { value: item.value, label: item.label, description: description, data: additionalData, children } as SelectOption;
      })
      .filter((item) => !!item?.label && !!item?.value);
  }

  getAutoCompleteData(
    settings: ACSettings,
    model: Record<string, unknown>,
    searchInput?: string,
    useFieldValueFromPath = false,
  ): Observable<SelectOption[]> {
    const search = searchInput?.trim() ?? '';

    let variables = {
      search: `${settings.searchSettings?.prefix ?? ''}${search}${settings.searchSettings?.suffix ?? ''}`,
    };

    if (settings.gqlVariables) {
      variables = {
        ...variables,
        ...settings.gqlVariables,
      };
    }

    if (settings.prefilledGqlVariables) {
      variables = {
        ...variables,
        ...settings.prefilledGqlVariables,
      };
    }

    if (settings.gqlVariablesFromModel && Object.keys(settings.gqlVariablesFromModel).length > 0) {
      const modelVars: Record<string, unknown> = {};
      for (const [key, path] of Object.entries(settings.gqlVariablesFromModel)) {
        modelVars[key] = useFieldValueFromPath ? getFieldValueFromPath(model, path ?? null) : getNestedFieldData(model, path ?? null);

        if (!modelVars[key] && variables[key as keyof typeof variables]) {
          // if prefilledGqlVariables exists and not gqlVariablesFromModel use prefilledGqlVariables gql value
          modelVars[key] = variables[key as keyof typeof variables];
        }
      }

      variables = {
        ...variables,
        ...modelVars,
      };
    }

    const sortByLabel = (a: { label?: string }, b: { label?: string }, desc: boolean): number => {
      const aa: string = a?.label?.toLowerCase()?.trim() ?? '';
      const bb: string = b?.label?.toLowerCase()?.trim() ?? '';
      return desc ? bb.localeCompare(aa) : aa.localeCompare(bb);
    };

    return this.gqlClient
      .query<{ data?: Record<string, unknown>[] }>({
        query: gql(settings.query),
        variables: variables,
      })
      .pipe(
        map((result) => {
          if (!result?.data) return [];

          const resultOptions: SelectOption[] = result.data.map((item) => this.searchValueToSelectOption(settings, item));

          if (!settings.sortByLabelAsc && !settings.sortByLabelDesc) {
            return resultOptions;
          }

          return resultOptions.sort((a, b) => sortByLabel(a, b, settings.sortByLabelDesc === true));
        }),
      );
  }

  initalValueToSelectOption(
    settings: ACSettings,
    fieldData: Record<string, unknown> | null | undefined,
    model: Record<string, unknown>,
    isJsonDataField?: boolean,
  ): SelectOption | null {
    if (!fieldData) {
      return null;
    }

    if (isJsonDataField) {
      // json data field
      if (!fieldData?.value || !fieldData?.label) {
        return null;
      }
      return { value: fieldData.value, label: fieldData.label } as SelectOption;
    }

    let value: string | number | undefined;
    let label = '';
    let data: Record<string, unknown> = {};

    const fieldDataAvailable = fieldData && Object.keys(fieldData).length > 0;
    const valueNamAndLabelsAvailable: boolean =
      !!settings.valueName && Array.isArray(settings.labelNames) && settings.labelNames.length > 0;

    if (settings.initalValueFromModel?.value) {
      const labelName = settings.initalValueFromModel?.label;
      const valueName = settings.initalValueFromModel?.value;

      if (labelName && valueName) {
        label = getNestedFieldData(model, labelName);
        value = getNestedFieldData(model, valueName);

        const dataPath = settings.initalValueFromModel?.data;
        if (dataPath) {
          data = dot.pick(dataPath, model);
        }
      }
    } else if (fieldDataAvailable && valueNamAndLabelsAvailable) {
      // legacy code in from settings
      label = (settings.labelNames ?? [])
        .map((d) => extractFieldData(fieldData, d))
        .filter((v) => !!v)
        .join('');
      value = fieldData![settings.valueName] as string | number;
    }

    if (!value || !label) return null;

    // set additional data
    if (fieldDataAvailable && settings.additionalDataFields && settings.additionalDataFields.length > 0) {
      for (const name of settings.additionalDataFields) {
        data[name] = fieldData[name];
      }
    }

    return {
      id: (fieldData?.id ?? null) as number | null,
      value,
      label,
      data,
      // multiselect additional props
      selectedValueName:
        fieldDataAvailable && settings.selectedLabel && (fieldData[settings.selectedLabel] as string | undefined)?.trim()
          ? (fieldData[settings.selectedLabel] as string).trim()
          : undefined,
    };
  }

  searchValueToSelectOption(settings: ACSettings, fieldData: Record<string, unknown> | null | undefined): SelectOption {
    let value: string | number | undefined;
    let label = '';
    let data: Record<string, unknown> = {};

    const fieldDataAvailable = fieldData && Object.keys(fieldData).length > 0;
    const valueNamAndLabelsAvailable: boolean =
      !!settings.valueName && Array.isArray(settings.labelNames) && settings.labelNames.length > 0;

    if (settings.searchValue?.value) {
      const labelName = settings.searchValue?.label;
      const valueName = settings.searchValue?.value;

      if (labelName && valueName) {
        label = getNestedFieldData(fieldData, labelName);
        value = getNestedFieldData(fieldData, valueName);

        const dataPath = settings.searchValue?.data;
        if (dataPath && fieldDataAvailable) {
          data = dot.pick(dataPath, fieldData);
        }
      }
    } else if (fieldDataAvailable && valueNamAndLabelsAvailable) {
      // legacy code in from settings
      label = (settings.labelNames ?? [])
        .map((d) => extractFieldData(fieldData, d))
        .filter((v) => !!v)
        .join('');
      value = fieldData![settings.valueName] as string | number;
    }

    // set additional data
    if (fieldDataAvailable && settings.additionalDataFields && settings.additionalDataFields.length > 0) {
      for (const name of settings.additionalDataFields) {
        data[name] = fieldData[name];
      }
    }

    // select item color
    let color: SelectColor | undefined;
    if (settings.color) {
      color = {
        textColor: settings.color.textColor ? dot.pick(settings.color.textColor, fieldData) : undefined,
        backgroundColor: settings.color.backgroundColor ? dot.pick(settings.color.backgroundColor, fieldData) : undefined,
        fixedTextColor: settings.color.fixedTextColor,
        fixedBackgroundColor: settings.color.fixedBackgroundColor,
      };
    }

    // additional descriptions
    const description = this.getDescription(settings?.descriptionSetting, fieldData);

    return {
      id: (fieldData?.id ?? null) as number | null,
      value,
      label,
      color: color,
      description,
      data,
      // multiselect additional props
      selectedValueName:
        fieldDataAvailable && settings.selectedLabel && (fieldData[settings.selectedLabel] as string | undefined)?.trim()
          ? (fieldData[settings.selectedLabel] as string).trim()
          : undefined,
    };
  }

  async assignSelf(acSettings: ACSettings, model: Record<string, unknown>) {
    let variables = {
      userId: this.auth.user()?.id,
    };

    // gql vars from model
    if (acSettings.gqlVariablesFromModelAssignSelf && Object.keys(acSettings.gqlVariablesFromModelAssignSelf).length > 0) {
      const modelVars: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(acSettings.gqlVariablesFromModelAssignSelf)) {
        modelVars[key] = getNestedFieldData(model, value ?? null);
      }

      variables = {
        ...variables,
        ...modelVars,
      };
    }

    return await firstValueFrom(
      this.gqlClient
        .query<{ data?: Record<string, unknown>[] }>({
          query: gql(acSettings.assignSelfQuery!),
          variables,
        })
        .pipe(
          map(
            (result) => result?.data?.map((item) => this.initalValueToSelectOption(acSettings, item, model)) as SelectOption[] | undefined,
          ),
        ),
    );
  }

  addNewContact(settings: AddNewContactSettings, model: Record<string, unknown>, onSubmitSuccess: (data: { id?: number }) => void): void {
    const prefillData: Record<string, unknown> = {};

    if (settings.addNewPrefillData && Object.keys(settings.addNewPrefillData).length > 0) {
      for (const [key, value] of Object.entries(settings.addNewPrefillData)) {
        const obj: Record<string, unknown> = {};
        if (value && typeof value === 'object') {
          for (const [k, val] of Object.entries(value)) {
            if (val && typeof val === 'string') {
              const pathVal = getFieldValueFromPath(model, val);
              if (pathVal) {
                obj[k] = pathVal;
              }
            }
          }
          if (Object.keys(obj).length > 0) {
            prefillData[key] = obj;
          }
        } else {
          const pathVal = getFieldValueFromPath(model, value as string | null | undefined);
          if (pathVal) {
            prefillData[key] = pathVal;
          }
        }
      }
    }

    this.formDialogService.openForm({
      formId: SupportedForm.contact,
      formMode: 'insert',
      dialogTitle: settings.addNewDialogTitle ?? 'Dodaj',
      onSubmitSuccessAction: ({ data }) => {
        onSubmitSuccess(data);
      },
      prefillData: Object.keys(prefillData).length > 0 ? prefillData : null,
    });
  }

  private getDescription(
    descriptionSetting: (string | { literal: string })[] | ((value: Record<string, unknown>) => string | DescriptionSetting[]),
    fieldData: Record<string, unknown> | undefined | null,
  ): string | DescriptionSetting[] {
    if (!descriptionSetting || !fieldData || Object.keys(fieldData).length < 1) {
      return '';
    }

    let description = '';

    if (typeof descriptionSetting === 'function') {
      try {
        if (Array.isArray(descriptionSetting)) {
          return descriptionSetting(fieldData) as DescriptionSetting[];
        }
        description = descriptionSetting(fieldData) as string;
      } catch (error) {
        description = '';
      }
    } else {
      description = (descriptionSetting ?? [])
        .map((d) => extractFieldData(fieldData, d))
        .filter((v) => !!v)
        .join('');
    }
    return description;
  }
}
