@if (state(); as state) { @switch (state) { @case ("error") {
<ngcontainer [ngTemplateOutlet]="errorState" />
} @case ("noSignature") {
<ngcontainer [ngTemplateOutlet]="noSignatureState" />
} @case ("signed") {
<ngcontainer [ngTemplateOutlet]="signedState" />
} @case ("drawing") {
<ngcontainer [ngTemplateOutlet]="drawingState" />
} @default {
<ngcontainer [ngTemplateOutlet]="loadingState" />
} } }

<ng-template #loadingState>
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
</ng-template>

<ng-template #errorState>
  <div class="error">{{ error() }}</div>
</ng-template>

<ng-template #noSignatureState>
  <div class="row-space-between">
    <div lass="signature-title">{{ 'signature_no_signature' | intl }}</div>
    <button (click)="newSignature()" [matTooltip]="'signature_new_signature' | intl" mat-mini-fab color="primary">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #signedState>
  <div class="signature-container">
    <div class="signature-header">
      <div class="signature-title">{{ 'signature_signature_is_done' | intl }}</div>
      @if (!isReadonly) {
      <button (click)="newSignature()" [matTooltip]="'signature_new_signature' | intl" mat-mini-fab color="primary">
        <mat-icon>edit</mat-icon>
      </button>
      }
    </div>
    <img
      class="signature"
      [ngStyle]="{ height: height, width: width }"
      style="object-fit: contain"
      [src]="signatureValue | trust : 'resourceUrl'"
    />
  </div>
</ng-template>

<ng-template #drawingState>
  <div class="signature-container">
    <div class="signature-header">
      <div class="signature-title">{{ 'signature_sign_document' | intl }}</div>
      @if (!isReadonly && signatureValue?.length) {
      <button (click)="clearSignature()" [matTooltip]="'signature_delete_signature' | intl" mat-mini-fab color="primary">
        <mat-icon>clear</mat-icon>
      </button>
      }
    </div>
    <canvas
      class="signature"
      [ngStyle]="{ height: height, width: width }"
      ejs-signature
      #signature
      id="signature"
      [disabled]="isReadonly"
      [maxStrokeWidth]="maxStrokeWidth"
      [minStrokeWidth]="minStrokeWidth"
      [velocity]="velocity"
      (change)="onSignatureChange()"
    ></canvas>
  </div>
</ng-template>
