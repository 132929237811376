
function _nextFocusFieldElement(formElement: Element, fieldElement: Element): HTMLElement | undefined {
  // if (!formElement) throw new Error('Invalid form element');
  if (!formElement) return;

  const inputEl = fieldElement.querySelector('input')!;
  const siblings = Array.from(formElement.querySelectorAll<HTMLElement>('input:not([disabled]), textarea:not([disabled]), rcg-html-editor')).map(
    (e) => {
      if (e.tagName !== 'RCG-HTML-EDITOR') return e;
      return e.querySelector('iframe')?.contentDocument?.querySelector<HTMLDivElement>('.k-content');
    }
  ).filter((e) => !!e).map((e) => e!);

  const i = siblings.indexOf(inputEl);
  if (i === -1) return undefined;
  return siblings[i + 1];
}

export function focusNextFormField(formElement: Element, fieldElement: Element) {
  const n = _nextFocusFieldElement(formElement, fieldElement);

  if (!n) return;
  setTimeout(() => n.focus());
}
