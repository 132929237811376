<ng-container *ngTemplateOutlet="error ? errorTemplate : formContentTemplate"></ng-container>

<ng-template #errorTemplate>
  <div class="error">
    <div>{{ 'error' | intl }}: {{ error }}</div>
  </div>
</ng-template>

<ng-template #formContentTemplate>
  <ng-container *ngIf="!error && status !== FormStatus.loading && formConfig && model && form">
    <div class="title" *ngIf="title">{{ title }}</div>

    <form class="form" [formGroup]="form" (ngSubmit)="submitForm()">
      <formly-form
        #formComponent
        class="html-field-fullscreen-parent"
        [form]="form"
        [fields]="fields"
        [model]="model"
        [options]="formOptions!"
        (modelChange)="model = $event"
        (ngAfterViewInit)="formLoaded()"
      ></formly-form>

      <div
        [ngClass]="{
          'form-buttons-stretch': formButtons.className === 'stretch',
          'form-buttons-start': formButtons.className === 'start',
          'form-buttons-center': formButtons.className === 'center',
          'form-buttons-end': formButtons.className === 'end'
        }"
        *ngIf="!hideFormButtons && (formConfig.formMode === 'insert' || formConfig.formMode === 'update')"
      >
        <button
          [ngStyle]="formButtons.submitButton?.style"
          [disabled]="form.invalid || form.pristine || status !== FormStatus.editing"
          mat-raised-button
          color="primary"
        >
          @switch (status) { @case (FormStatus.submitting) {
          <mat-spinner mode="indeterminate" diameter="24"></mat-spinner>
          } @case (FormStatus.editing) {
          {{ formButtons.submitButton?.title ?? 'confirm' | intl }}
          } @case (FormStatus.submitted) {
          <span style="display: flex; align-items: center; gap: 4px; color: black">
            <mat-icon style="color: green">done</mat-icon>
            {{ 'form_button_submitted_caption' | intl }}
          </span>
          } }
        </button>
      </div>
    </form>

    <ng-container *ngIf="showDebugForm && form && model">
      <div class="form-debugger">
        <rcg-formly-debug [form]="form" [model]="model"></rcg-formly-debug>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
