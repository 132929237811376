@if(error(); as error){
<span style="display: flex; color: red; font-size: 10px">{{ error }}</span>
}@else{
<div style="display: flex">
  <mat-select
    [id]="id"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
    [compareWith]="compareWith"
    [required]="!!props.required"
    [multiple]="false"
    (selectionChange)="onChange($event)"
    [errorStateMatcher]="errorStateMatcher"
    [value]="value"
    [disabled]="!!props.disabled"
    panelWidth=""
  >
    @for (option of optionsValues(); track $index) {
    <mat-option [value]="option">
      {{ option?.label }}
    </mat-option>
    }
  </mat-select>
  @if(value && props.clearIcon && !props.disabled ){
  <mat-icon style="font-size: 20px" matSuffix (click)="clearValue()">clear</mat-icon>
  }
</div>
}
