const ctx = document.createElement("canvas").getContext("2d")!;

export function normalizeColorToHex(color: string) {
  ctx.fillStyle = color;
  return ctx.fillStyle;
}

export function getContrastYIQ(hexcolor: string){
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);

  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return (yiq >= 128) ? 'black' : 'white';
}