<rcg-dialog
  [dialogRef]="dialogRef"
  icon="enhanced_encryption"
  [title]="'add_mfa_dialog_title' | intl"
  [iconTooltip]="'add_mfa_dialog_title' | intl"
  [disabledToolbarActions]="['minimize']"
>
  @switch (dialogConfig?.type ?? '') { @case ('OTP/RFC6238') {
  <rcg-totp-mfa-adder class="adder" [dialogRef]="dialogRef" (afterAdded)="afterAdded()"></rcg-totp-mfa-adder>
  } @case ('OTP/SMS') {
  <rcg-sms-mfa-adder class="adder" [dialogRef]="dialogRef" (afterAdded)="afterAdded()"></rcg-sms-mfa-adder>
  } @case ('recovery/code') {
  <rcg-recovery-code-mfa-adder class="adder" [dialogRef]="dialogRef" (afterAdded)="afterAdded()"></rcg-recovery-code-mfa-adder>
  } @default {
  <div class="error-container">
    <h2 style="color: red">{{ 'invalid_mfa_type' | intl : { params: { type: dialogConfig?.type ?? '???' } } }}</h2>
  </div>
  } }
</rcg-dialog>
