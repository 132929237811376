import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { gql } from '@apollo/client/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '@rcg/auth';
import {
  AttachmentBlobUrls,
  EditorEditMode,
  EmlAttachment,
  EmlEmail,
  IEditorValue,
  RcgContact,
  RcgFieldType,
  RcgFormlyFieldProps,
} from '@rcg/core/models';
import { AttachmentsService, EmlService, HTMLAttachmentResolverService } from '@rcg/core/services';
import { aCIDRegex } from '@rcg/core/utils/attachment/regex';
import { FormDialogService } from '@rcg/forms/services';
import { getKnowledgeBaseFormId } from '@rcg/forms/utils/supported-forms-utils';
import { GraphqlClientService } from '@rcg/graphql';
import { MessageService } from '@rcg/standalone/services';
import { FrameDOMParser } from '@rcg/standalone/utils/dom-utils';
import { htmlHasVisibleContent, htmlToPlainText, normalizeAndCleanHTMLAsDoc } from '@rcg/standalone/utils/html-utils';
import { Observable, combineLatest, debounceTime, firstValueFrom, map, merge, of, pairwise, startWith, take } from 'rxjs';
import { BeforeSubmitProp, beforeSubmitPropertyName } from '../../containers/formly-form/formly-form.component';
import { HtmlEditorComponent } from './html-editor/html-editor.component';

const frameDomParser = new FrameDOMParser();

const editorAdditionalHeadElementsList = [
  ...new DOMParser().parseFromString(
    `
<html>
  <head>
    <base target="_blank">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans&family=Noto+Sans+Mono&family=Roboto&display=swap" rel="stylesheet">
    <style>
      .k-content p, p {
        font-family: Roboto;
        font-size: 14px;
        margin: 0;
        min-height: 1em;
      }

      img {
        max-width: 100%;
        height: auto !important;
      }
    </style>
  </head>
</html>
`,
    'text/html',
  ).head.children,
];

function editorAdditionalHeadElements() {
  return editorAdditionalHeadElementsList.map((e) => e.cloneNode(true));
}

const editorAdditionalHeadElementsOuterHtml = editorAdditionalHeadElementsList.map((e) => e.outerHTML);

interface HtmlEditorFieldSettings {
  startInEditMode?: boolean | ((model: unknown) => void);
  autoScaleHeightToUnusedFormSpace?: boolean;
  eml?: { id_key?: string };
  editOnClick?: boolean;
  stopEditOnUnfocus?: boolean;
  showCreateKnowledgeBaseButton?: boolean;
  listSplit?: {
    mutation: string;
    getVariables: (itemData: (readonly [string, string])[], model: Record<string, unknown>, form: FormGroup) => Record<string, unknown>;
  };
  inlineAttachments?: boolean;
  inlineAttachmentsFieldName?: string;
  mapInitalValue?: (value: IEditorValue | undefined, model: Record<string, unknown>) => IEditorValue;
  contactsInput?: boolean;
  contactsField?: string;
  formatPastedLinks?: boolean;
}

@Component({
  selector: 'rcg-html-editor-field',
  templateUrl: './html-editor-field.component.html',
  styleUrls: ['./html-editor-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlEditorFieldComponent
  extends RcgFieldType<IEditorValue, RcgFormlyFieldProps<Record<string, unknown>, HtmlEditorFieldSettings>>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('editor') editor?: HtmlEditorComponent;
  @ViewChild('editor', { read: ElementRef }) editorEl?: ElementRef<HTMLElement>;

  emlEmail!: EmlEmail | null;
  emlAttachments: EmlAttachment[] = [];
  emlAttachmentsThumbnailBlobUrls: AttachmentBlobUrls = {};
  emlOriginalHtml: string | null = null;

  private isValueUpdating = false;

  loading = false;
  loadedAttachmentsFraction = 1;
  unresolvableAttachments: Set<string> = new Set([]);

  error?: string;

  editorMode: EditorEditMode = 'readonly';

  splitLists: (HTMLUListElement | HTMLOListElement)[] = [];

  initialImageAttachmentIds: number[] = [];

  constructor(
    private emlService: EmlService,
    private attachmentsService: AttachmentsService,
    private htmlAttachmentResolver: HTMLAttachmentResolverService,
    private changeDetectionRef: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    private formDialogService: FormDialogService,
    private gqlClient: GraphqlClientService,
    private messageService: MessageService,
    private injector: Injector,
  ) {
    super();
  }

  async hasValueVisibleContent(): Promise<boolean> {
    const value = this.outValue ?? this.editorValue;

    if (value?.plain) return true;

    const html = value?.html as string | undefined;
    if (!html) return false;

    return await htmlHasVisibleContent(html);
  }

  async ngOnInit() {
    try {
      if (this.props?.settings?.mapInitalValue && typeof this.props.settings.mapInitalValue === 'function') {
        this.value = this.props.settings.mapInitalValue(this.value, this.model);
      }

      const startInEditMode = this.to?.settings?.startInEditMode;

      const safeGetStartInEditModeValue = () => {
        if (typeof startInEditMode === 'function') {
          try {
            return startInEditMode(this.model);
          } catch (e) {
            console.warn('[HTML Editor] An error occured while getting startInEditMode value:', e);
            return false;
          }
        }

        return startInEditMode;
      };

      this.editorMode = safeGetStartInEditModeValue() ? 'edit' : 'readonly';

      this.loading = true;
      this.loadedAttachmentsFraction = 0;
      this.changeDetectionRef.markForCheck();

      const { editorValue, emlOriginalHtml } = await this.getInitialEditorValue(this.value);

      if (editorValue.eml_id && this.emlEmail) {
        this.emlAttachments = this.emlService.getEmlAttachments(this.emlEmail).map((a) => ({
          ...a,
          id: undefined,
          file_name: a.filename,
        }));

        this.emlAttachmentsThumbnailBlobUrls = this.attachmentsService.getAttachmentsBlobUrls(this.emlAttachments);
        this.changeDetectionRef.markForCheck();
      }

      let setEditorUpdateTimeout: number | undefined;

      const debouncedSetEditorValue = (value: IEditorValue) => {
        window.clearTimeout(setEditorUpdateTimeout);
        setEditorUpdateTimeout = window.setTimeout(() => {
          this.setInitialAttachmentsValue(value.html ?? '');
          return this.setEditorValue(value);
        }, 300);
      };

      let progress$: Observable<number>;

      // Resolve HTML attachments and set initial editor value
      if (editorValue.eml_id) {
        if (this.emlEmail && (editorValue.html || emlOriginalHtml)) {
          const original = this.htmlAttachmentResolver.resolve(emlOriginalHtml, Array.from(this.unresolvableAttachments), [
            editorValue.eml_id,
            this.emlEmail,
          ]);
          const edited = this.htmlAttachmentResolver.resolve(editorValue.html, Array.from(this.unresolvableAttachments), [
            editorValue.eml_id,
            this.emlEmail,
          ]);

          const original$ = merge(
            original[1],
            original[0].pipe(
              map((html) => {
                this.emlOriginalHtml = html;
                return 1;
              }),
            ),
            original[2].pipe(
              map((ids) => {
                ids.forEach((id) => this.unresolvableAttachments.add(id));
                return 1;
              }),
            ),
          );

          const edited$ = merge(
            edited[1],
            edited[0].pipe(
              map((html) => {
                debouncedSetEditorValue({ ...editorValue, html });
                return 1;
              }),
            ),
            edited[2].pipe(
              map((ids) => {
                ids.forEach((id) => this.unresolvableAttachments.add(id));
                return 1;
              }),
            ),
          );

          progress$ = combineLatest([original$, edited$]).pipe(
            map(([original, edited]) => {
              return (original + edited) / 2;
            }),
          );
        } else {
          progress$ = of(1);
        }
      } else if (editorValue.html) {
        const resolved = this.htmlAttachmentResolver.resolve(editorValue.html, Array.from(this.unresolvableAttachments));

        progress$ = merge(
          resolved[1],
          resolved[0].pipe(
            map((html) => {
              debouncedSetEditorValue({ ...editorValue, html });
              return 1;
            }),
          ),
          resolved[2].pipe(
            map((ids) => {
              ids.forEach((id) => this.unresolvableAttachments.add(id));
              return 1;
            }),
          ),
        );
      } else {
        debouncedSetEditorValue(editorValue);
        progress$ = of(1);
      }

      progress$.pipe(debounceTime(10)).subscribe({
        next: (progress) => {
          this.loading = false;
          this.loadedAttachmentsFraction = progress;
          this.changeDetectionRef.markForCheck();
        },
        error: (error) => {
          this.error = `${error}`;
          this.loading = false;
          this.changeDetectionRef.markForCheck();
        },
        complete: () => {
          this.loadedAttachmentsFraction = 1;
          this.changeDetectionRef.markForCheck();
        },
      });
    } catch (error) {
      this.error = `${error}`;
      this.loading = false;
      this.changeDetectionRef.markForCheck();
    }

    const bsForm = this.form as unknown as { [beforeSubmitPropertyName]: BeforeSubmitProp };
    bsForm[beforeSubmitPropertyName] ??= {};

    bsForm[beforeSubmitPropertyName][JSON.stringify(this.key)] = async () => {
      if (!this.editor) throw 'HTML editor not rendered';

      if (this.editor.editorMode === 'edit') {
        const bsStartTime = new Date().getTime() - 100; //? Subtract 100ms to avoid race conditions and add tolerance

        await new Promise((res) => setTimeout(res, HtmlEditorComponent.valueUpdateDebounceTimeMs + 110));

        const emChanged = firstValueFrom(this.editor.editModeChanged);
        this.editor.changeEditMode('readonly');
        await emChanged;

        while (this.isValueUpdating || this._valueChangeTime < bsStartTime) {
          await new Promise((res) => setTimeout(res, 50));
        }

        await new Promise((res) => setTimeout(res, HtmlEditorComponent.valueUpdateDebounceTimeMs + 110));
      }

      if (this.props.settings?.inlineAttachments) {
        const newHtml = await this.processAttachments(this.value.html);

        await this.checkDeletedAttachments(newHtml ?? '');

        this.value.html = newHtml;
        this.formControl.setValue(this.value);

        this.field.formControl.setValue(this.value);

        await new Promise((res) => setTimeout(res, 0));
      }
    };

    const untypedFormControl = this.formControl as UntypedFormControl;

    untypedFormControl.addAsyncValidators([
      async () => (this.isValueUpdating ? { valueUpdating: 'Posodabljanje vrednosti...' } : null),
      async () => (this.props.required && !(await this.hasValueVisibleContent()) ? { required: 'Polje je obvezno' } : null),
      async () => (this.loadedAttachmentsFraction !== 1 ? { loadingAttachments: 'Nalaganje priponk' } : null),
    ]);

    this.formControl.updateValueAndValidity();
  }

  private _valueChangeTime = 0;

  private autoScaleIntersectionObserver?: IntersectionObserver;

  ngAfterViewInit(): void {
    if (this.to?.settings?.autoScaleHeightToUnusedFormSpace) {
      try {
        const formEl = this.getFormElement();
        const autoScaleEl = this.getAutoScaleElement();

        setTimeout(() => this.autoScaleHeightToUnusedFormSpace(autoScaleEl));

        this.autoScaleIntersectionObserver = new IntersectionObserver(
          (int) => {
            if (!int[0].isIntersecting) return;

            setTimeout(() => this.autoScaleHeightToUnusedFormSpace(autoScaleEl));
          },
          {
            root: formEl,
            threshold: 1.0,
          },
        );

        this.autoScaleIntersectionObserver.observe(this.elementRef.nativeElement);
      } catch (error) {
        console.warn('HTML editor auto scale failed:', error);
      }
    }

    this.formControl.valueChanges.pipe(startWith(undefined), pairwise()).subscribe(([p, c]) => {
      if (p === c || !c) return;
      this.valueChanged(c);
    });

    this.formControl.valueChanges.pipe(take(1), startWith(undefined), debounceTime(1000)).subscribe(() => this.updateSplitLists());

    if (this.field.focus) {
      setTimeout(() => {
        if (!this.editor) console.warn('HTML editor initial focus not possible - editor == null (requires startInEditMode)');
        this.editor?.focusEditor();
      });
    }
  }

  private async setInitialAttachmentsValue(html: string) {
    const doc = await normalizeAndCleanHTMLAsDoc(html);
    const images = doc.getElementsByTagName('img');

    const ids: number[] = Array.prototype.slice
      .call(images)
      .map((img: HTMLImageElement) => this.extractCID(img.src))
      .filter((id) => id !== null) as number[];

    // considering existing value because initial attachments could be called when adding predefined answers into field
    this.initialImageAttachmentIds = [
      ...this.initialImageAttachmentIds,
      ...ids.filter((id) => !this.initialImageAttachmentIds.includes(id)),
    ];
  }

  private async checkDeletedAttachments(html: string) {
    const doc = await normalizeAndCleanHTMLAsDoc(html);
    const images = doc.getElementsByTagName('img');

    const ids = Array.prototype.slice
      .call(images)
      .map((img: HTMLImageElement) => this.extractCID(img.src))
      .filter((id) => id !== null) as number[];

    const fieldName = this.props.settings?.inlineAttachmentsFieldName ?? 'attachments';
    const field = this.form.get(fieldName);

    if (!field) {
      console.warn('[inlineAttachmentsAdded] Inline attachments field not found:', fieldName);
      return;
    }

    const fVal = field.value;
    let val = fVal && Array.isArray(fVal) ? fVal : [];

    const deleted = this.initialImageAttachmentIds.filter((id) => !ids.includes(id));

    val = val.filter((id) => !deleted.includes(Math.abs(id)));

    this.initialImageAttachmentIds.forEach((id) => {
      if (!deleted.includes(id) && !val.includes(-id)) {
        val.push(-id);
      }
    });

    field.setValue(val);
  }

  private extractCID(url: string) {
    const match = url.match(/aCID=(\d+)/);
    if (match) {
      return parseInt(match[1], 10);
    } else {
      return null; // aCID not found
    }
  }

  valueUpdating(updating: boolean) {
    this.isValueUpdating = updating;
    this.formControl.updateValueAndValidity();
  }

  async valueChanged(value: IEditorValue) {
    try {
      this.outValue = value;

      if (aCIDRegex.test(value.html ?? '') && this.loadedAttachmentsFraction === 1) {
        const resolved = this.htmlAttachmentResolver.resolve(value.html, Array.from(this.unresolvableAttachments));

        let setEditorUpdateTimeout: number | undefined;

        const debouncedSetEditorValue = (value: IEditorValue) => {
          window.clearTimeout(setEditorUpdateTimeout);
          setEditorUpdateTimeout = window.setTimeout(() => {
            this.setInitialAttachmentsValue(value.html ?? '');
            return this.setEditorValue(value);
          }, 300);
        };

        let progress$: Observable<number> = of(0);

        progress$ = merge(
          resolved[1],
          resolved[0].pipe(
            map((html) => {
              if (html !== value.html) debouncedSetEditorValue({ ...value, html });
              return 1;
            }),
          ),
          resolved[2].pipe(
            map((ids) => {
              ids.forEach((id) => this.unresolvableAttachments.add(id));
              return 1;
            }),
          ),
        );

        progress$.pipe(debounceTime(10)).subscribe({
          next: (progress) => {
            this.loading = false;
            this.loadedAttachmentsFraction = progress;
            this.changeDetectionRef.markForCheck();
          },
          error: (error) => {
            this.error = `${error}`;
            this.loading = false;
            this.changeDetectionRef.markForCheck();
          },
          complete: () => {
            this.loadedAttachmentsFraction = 1;
            this.changeDetectionRef.markForCheck();
          },
        });
      }

      if (this.htmlAttachmentResolver.sealBlobs(value.html) === this.htmlAttachmentResolver.sealBlobs(this.value.html)) {
        return;
      }
      if (this.formControl.value !== value) {
        this.markFormControlDirty();
      }
    } catch (error) {
      this.error = `${error}`;
    } finally {
      this._valueChangeTime = new Date().getTime();
      this.changeDetectionRef.markForCheck();
      this.formControl.updateValueAndValidity();
    }
  }

  contactAdded(contact: RcgContact) {
    if (this.props.settings?.contactsInput && this.props.settings.contactsField) {
      const contactsField = this.form.get(this.props.settings.contactsField);
      const newVal = { id: contact.id, label: `${contact.first_name} ${contact.last_name}`, value: contact.email };
      contactsField?.setValue([...contactsField.value, newVal]);
      contactsField?.markAsDirty();
      contactsField?.updateValueAndValidity();
    }
  }

  override ngOnDestroy(): void {
    window.clearTimeout(this.autoScaleHeightRetryTimeout);

    try {
      this.autoScaleIntersectionObserver?.disconnect();
    } catch (error) {
      // ignore
    }

    super.ngOnDestroy();
  }

  async processAttachments(html: string | null) {
    if (!html) {
      return null;
    }

    const doc = await normalizeAndCleanHTMLAsDoc(html);
    const images = doc.getElementsByTagName('img');

    const fileArray: [File, Blob, HTMLImageElement][] = [];

    for (let i = 0; i < images.length; i++) {
      const img: HTMLImageElement = images[i];

      if (/^data:image\/[a-z]+;base64,/.test(img.src)) {
        const extension = this.getExtension(img);
        if (extension) {
          const blob = await (await fetch(img.src)).blob();
          fileArray.push([new File([blob], `inline_image.${extension}`), blob, img]);
        }
      }
    }

    if (fileArray.length > 0) {
      const files = fileArray.map(([f]) => f);
      const ids = await this.attachmentsService.uploadAttachments(files);

      for (let i = 0; i < ids.length; i++) {
        const src = `${URL.createObjectURL(fileArray[i][1])}#aCID=${ids[i]}`;
        fileArray[i][2].src = src;
      }

      this.inlineAttachmentsAdded(ids);
    }

    const cleanHTML = doc.documentElement.outerHTML;

    return cleanHTML;
  }

  getExtension(element: HTMLImageElement) {
    const mimeTypeRegex = /^data:([a-z]+\/[a-z]+);/i;

    const mimeType = element.src.match(mimeTypeRegex)?.[1];
    switch (mimeType) {
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      default:
        return;
    }
  }

  async downloadEmlAttachment(attachment: EmlAttachment): Promise<void> {
    try {
      if (!this.value?.eml_id) {
        console.error('Download eml attachment error. Eml id is null');
        return;
      }
      const blobUrl = await this.emlService.downloadEmlAttachmentBlobUri(attachment);
      this.downloadUrl(blobUrl, attachment.filename);
    } catch (e) {
      console.error('Download eml attachment error', e);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async getInitialEditorValue(value: any | null): Promise<{ editorValue: IEditorValue; emlOriginalHtml: string | null }> {
    if (!value) {
      return {
        editorValue: {
          eml_id: null,
          html: '',
          plain: '',
        } as IEditorValue,
        emlOriginalHtml: null,
      };
    }

    const emlSettings = this.to?.settings?.eml;
    let emlIdKey: string | null = null;

    if (typeof emlSettings?.id_key === 'string') {
      emlIdKey = emlSettings.id_key;
    } else {
      emlIdKey = 'eml_id';
    }

    let editorValue: IEditorValue = {
      eml_id: emlIdKey ? value?.[emlIdKey] ?? null : null,
      html: value?.html ?? '',
      plain: value?.plain ?? '',
    } as IEditorValue;

    let emlOriginalHtml: string | null = null;
    if (editorValue.eml_id) {
      // eml
      this.emlEmail = await this.emlService.getEmlEmail(editorValue.eml_id);

      if (this.emlEmail && this.emlEmail?.body) {
        emlOriginalHtml = this.emlService.getEmlBodyHtml(this.emlEmail);

        if (editorValue.html) {
          // set eml has changed html and original eml html
          editorValue = {
            eml_id: editorValue.eml_id,
            html: editorValue.html,
            plain: editorValue.plain,
          } as IEditorValue;
        } else {
          editorValue = {
            eml_id: editorValue.eml_id,
            html: emlOriginalHtml ?? '',
            plain: this.emlService.getEmlBodyPlainText(this.emlEmail),
          } as IEditorValue;
        }
      }
    }
    return {
      editorValue: {
        eml_id: editorValue.eml_id,
        html: editorValue.html ? editorValue.html : editorValue.plain ? editorValue.plain : '',
        plain: editorValue.plain ? editorValue.plain : editorValue.html ? await htmlToPlainText(editorValue.html) : '',
      },
      emlOriginalHtml: emlOriginalHtml,
    };
  }

  private initialValue?: IEditorValue;
  private editorValue?: IEditorValue;
  private outValue?: IEditorValue;

  private async setEditorValue(value: IEditorValue) {
    const oldHtmlDom = await frameDomParser.parseFromString(this.initialValue?.html ?? '');
    const htmlDom = await frameDomParser.parseFromString(value.html ?? '');

    const styleElements: HTMLStyleElement[] = [];

    const styles = htmlDom.getElementsByTagName('style');
    for (const style of styles) {
      style.remove();

      if (!editorAdditionalHeadElementsOuterHtml.includes(style.outerHTML)) {
        styleElements.push(style);
      }
    }

    htmlDom.head.append(...oldHtmlDom.head.children);

    for (const c of [...htmlDom.head.children]) {
      if (editorAdditionalHeadElementsOuterHtml.includes(c.outerHTML)) {
        c.remove();
      }
    }

    htmlDom.head.append(...editorAdditionalHeadElements());

    for (const styleEl of styleElements) {
      htmlDom.head.append(styleEl);
    }

    this.outValue = undefined;

    this.editorValue = {
      ...value,
      html: htmlDom.documentElement.outerHTML,
    } as IEditorValue;

    if (!this.initialValue) {
      this.initialValue = this.editorValue;
    }

    this.value = this.editorValue;
    this.changeDetectionRef.markForCheck();
  }

  editModeChanged() {
    const val = this.outValue ?? this.editorValue;
    if (val) this.setEditorValue(val);

    this.formControl.updateValueAndValidity();
  }

  inlineAttachmentsAdded(attachmentIds: number[]) {
    const fieldName = this.props.settings?.inlineAttachmentsFieldName ?? 'attachments';
    const field = this.form.get(fieldName);

    if (!field) {
      console.warn('[inlineAttachmentsAdded] Inline attachments field not found:', fieldName);
      return;
    }

    const fVal = field.value;
    const val = fVal && Array.isArray(fVal) ? fVal : [];

    //? Sign bit used as inline flag
    val.push(...attachmentIds.map((id) => -id));

    field.setValue(val);
    field.markAsDirty();
  }

  private markFormControlDirty() {
    if (!this.formControl.dirty) {
      this.formControl.markAsDirty();
    }

    this.formControl.updateValueAndValidity();
  }

  private downloadUrl(url: string, filename: string) {
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    a.click();
  }

  private getFormElement() {
    const el = this.elementRef.nativeElement;

    const formEl = el.closest<HTMLElement>('formly-form');
    if (!formEl) throw 'Field is not contained inside form';

    return formEl;
  }

  private getAutoScaleElement() {
    const el = this.elementRef.nativeElement;

    const scaleEl = el.closest<HTMLElement>('formly-form, .mat-mdc-tab-body-content, .rcg-html-editor-autoscale-container');
    if (!scaleEl) throw 'Field is not contained inside auto-scalable element';

    return scaleEl;
  }

  private autoScaleHeightRetryTimeout?: number;

  private autoScaleHeightToUnusedFormSpace(formEl: HTMLElement, tries = 0) {
    if (!this.editor) {
      if (tries < 5) {
        this.autoScaleHeightRetryTimeout = window.setTimeout(() => this.autoScaleHeightToUnusedFormSpace(formEl, tries + 1), 200);

        return;
      } else {
        console.warn('[autoScaleHeightToUnusedFormSpace]', 'HTML editor not rendered');
        return;
      }
    }

    this.editor.autoScaleHeightToUnusedSpace(formEl);
  }

  public async createKnowledgeBase(event: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    const html = this.editor!.getReadonlyIframeSelection();
    if (!html) return;

    const authService = this.injector.get(AuthService);

    const tenant = authService.tenant()!;
    const formId = getKnowledgeBaseFormId(tenant.id);

    this.formDialogService.openForm({
      formMode: 'insert',
      formId,
      dialogTitle: 'Nova baza znanja',
      prefillData: {
        form_id: formId,
        answer: {
          sl: {
            html,
            eml_id: this.value.eml_id,
          },
        },
      },
    });
  }

  private updateSplitListsTimeout?: number;

  private updateSplitLists(tries = 1) {
    clearTimeout(this.updateSplitListsTimeout);

    const editorEl = this.editorEl?.nativeElement;
    if (!editorEl) {
      if (tries >= 5) return;

      this.updateSplitListsTimeout = window.setTimeout(() => this.updateSplitLists(tries + 1), 500);
      return;
    }

    const root = editorEl.getElementsByTagName('iframe')[0].contentDocument?.body;
    if (!root) return;

    this.splitLists = [...root.getElementsByTagName('ul'), ...root.getElementsByTagName('ol')];

    if (!this.splitLists.length) {
      if (tries >= 10) return;
      this.updateSplitListsTimeout = window.setTimeout(() => this.updateSplitLists(tries + 1), 500);
    }

    this.changeDetectionRef.markForCheck();
  }

  public showSplitListDialog(event: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    const settings = this.props.settings!.listSplit!;
    const items = this.splitLists.flatMap((l) => Array.from(l.querySelectorAll('li')));

    this.formDialogService.openCustomForm({
      dialogTitle: 'Razdeli seznam',
      formOptions: {},
      formFields: [
        {
          hooks: {
            onInit: ({ form }) => {
              setTimeout(() => form?.markAsDirty());
            },
          },
        },
        {
          fieldGroupClassName: 'd-flex flex-column align-items-stretch',
          fieldGroup: [
            {
              key: 'all',
              type: 'checkbox',
              defaultValue: true,
              props: {
                label: 'Vsi',
                change: (config) => {
                  const form = config.form;
                  if (!form) return;

                  const value = config.formControl?.value;
                  if (value === undefined) return;

                  for (let i = 0; i < items.length; i++) {
                    config.form.get(`checkbox-${i}`)?.setValue(value);
                  }
                },
              },
            },
            ...items.map(
              (item, i) =>
                ({
                  key: `checkbox-${i}`,
                  type: 'checkbox',
                  wrappers: [],
                  props: {
                    label: item.innerText,
                    change: (config) => {
                      config?.form?.get('all')?.setValue(undefined);
                    },
                  },
                } as FormlyFieldConfig),
            ),
          ],
        },
      ],
      model: {
        ...items.map((_, i) => ({ [`checkbox-${i}`]: true })).reduce((a, b) => ({ ...a, ...b }), {}),
      },
      closeDialogOnSubmitted: true,
      onSubmitAction: async ({ model }) => {
        const itemData = Object.entries(model ?? {})
          .filter(([k, v]) => v && k.startsWith('checkbox-'))
          .map(([k]) => items[+k.substring(9)])
          .map((e) => [e.innerText, e.innerHTML] as const);

        try {
          await firstValueFrom(
            this.gqlClient.mutate({
              mutation: gql(settings.mutation),
              variables: settings.getVariables(itemData, this.model, this.form),
            }),
          );
        } catch (error) {
          this.messageService.showErrorSnackbar('Napaka:', error);
        }
      },
    });
  }

  onAttachmentThumbnailClicked(attachment: EmlAttachment) {
    this.formDialogService.openAttachmentsViewer({
      selectedAttachment: attachment,
      attachments: this.emlAttachments,
      thumbnailBlobUrls: this.emlAttachmentsThumbnailBlobUrls,
      canDelete: false,
    });
  }
}
