import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectChange } from '@angular/material/select';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/material/form-field';
import { Observable, catchError, isObservable, of, tap } from 'rxjs';

export interface SelectOption {
  value: unknown;
  label: string;
}

export interface SelecFieldtProps extends FormlyFieldProps {
  options: SelectOption[];
  optionsFunc: (model: Record<string, unknown>) => SelectOption[] | Observable<SelectOption>[];
  clearIcon: boolean | undefined;
  setFieldValueAndLabel?: boolean;
}

@Component({
  selector: 'rcg-select-field',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RcgSelectFieldComponent extends FieldType<FieldTypeConfig<SelecFieldtProps>> implements OnInit {
  optionsValues = signal<SelectOption[]>([]);

  private destroyRef = inject(DestroyRef);

  error = signal<string | null>(null);

  ngOnInit(): void {
    this.value = this.getInitalValue();

    if (this.props.options) {
      this.optionsValues.set((this.props.options as SelectOption[]) ?? []);
      return;
    }

    const optionsFunc = this.props.optionsFunc;

    if (optionsFunc && typeof optionsFunc === 'function') {
      const value = (optionsFunc as (model: Record<string, unknown>) => SelectOption[] | Observable<SelectOption[]>)(this.field.model);

      if (isObservable(value)) {
        value
          .pipe(
            tap(() => this.error.set(null)),
            catchError((error) => {
              this.error.set('Error: ' + error?.message ?? '');
              return of([]);
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe((options) => {
            this.optionsValues.set((options as SelectOption[] | undefined) ?? []);
          });
      } else {
        this.optionsValues.set(value ?? []);
      }
    }
  }

  onChange(event: MatSelectChange) {
    this.value = this.getValue(event?.value as SelectOption);
    this.formControl.markAsTouched();
  }

  clearValue() {
    this.value = null;
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  compareWith(o1: SelectOption | undefined, o2: SelectOption | undefined) {
    if (o1 === undefined || o2 === undefined) return false;
    if (o1 === null || o2 === null) return false;

    return (o1?.value ?? o1) === (o2?.value ?? o2);
  }

  private getInitalValue() {
    if (this.value) {
      return this.value;
    }

    // default value only if insert mode
    if (this.formState.query_variable && !this.model?.[this.formState.query_variable] && this.field.defaultValue) {
      const defaultValue = typeof this.field.defaultValue === 'function' ? this.field.defaultValue() : this.field.defaultValue;
      return defaultValue ?? null;
    }
    return null;
  }

  getValue(select: SelectOption | null | undefined) {
    if (this.props.setFieldValueAndLabel) {
      return select ?? null;
    } else {
      return select?.value ?? null;
    }
  }
}
