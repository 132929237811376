<div class="chips">
  <mat-chip-grid #chipList aria-label="Item selection">
    <mat-chip-row
      [disabled]="props.readonly === true || props.disabled === true"
      *ngFor="let item of selectedValues"
      [removable]="removable"
      (removed)="remove(item)"
      (click)="changeSort(item)"
      [matTooltip]="item.direction ?? '' | intl"
    >
      <div>{{ item?.label ?? '' }}</div>
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      @if (item.direction==='ascending') {
      <mat-icon matChipAvatar>arrow_upward</mat-icon>
      } @if (item.direction==='descending') {
      <mat-icon matChipAvatar>arrow_downward</mat-icon>
      }
    </mat-chip-row>
    <input
      #input
      [id]="id"
      type="text"
      [formControl]="inputFormControl"
      [formlyAttributes]="field"
      [required]="props.required ?? false"
      [placeholder]="props.placeholder ?? ''"
      [tabIndex]="props.tabindex"
      [readonly]="props.readonly === true || props.disabled === true"
      [matAutocomplete]="autocomplete"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="selected($event)" [panelWidth]="'auto'">
    <mat-option *ngFor="let item of autocompleteOptions" [value]="item">
      {{ item.label }}
    </mat-option>
  </mat-autocomplete>
</div>
