<mat-tab-group (selectedIndexChange)="selectedTab.set($event)" mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">business</mat-icon>
      @if (!visible) { {{ 'organization' | intl }} }
    </ng-template>

    <rcg-formly-form class="organization-form" *ngIf="organizationFormConfig$ | async as formConfig" [formConfig]="formConfig">
    </rcg-formly-form>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">contacts</mat-icon>
      @if (!visible) {
      <span>{{ 'contacts' | intl }}</span
      >&nbsp; } @if ((contacts$ | async); as contacts) { @if(contacts?.length){
      <span>({{ contacts.length }})</span>
      } }
    </ng-template>

    <ng-template matTabContent>
      <div *ngIf="contacts$ | async as contacts" class="tab">
        <mat-form-field class="search-field">
          <input
            #searchInput
            matInput
            [placeholder]="'placeholder_search' | intl"
            (input)="searchContactsR.data = searchInput.value"
            (change)="searchContactsR.data = searchInput.value"
          />
          <mat-icon
            matSuffix
            *ngIf="searchInput.value"
            style="cursor: pointer"
            (click)="searchInput.value = ''; searchContactsR.data = searchInput.value"
            >clear</mat-icon
          >
          <mat-icon matSuffix *ngIf="!searchInput.value">search</mat-icon>
        </mat-form-field>

        <mat-nav-list class="contacts-list" *ngIf="contacts?.length; else noContactsTemplate">
          <cdk-virtual-scroll-viewport [itemSize]="68">
            <mat-list-item *cdkVirtualFor="let contact of contacts" (click)="openContact(contact)">
              <mat-icon matListItemIcon>person</mat-icon>
              <div matListItemTitle>
                <span *ngIf="contact.first_name; else noFirstNameTemplate">{{ contact.first_name }}</span>
                <ng-template #noFirstNameTemplate>
                  <span style="opacity: 0.5">&lt;{{ 'no_name' | intl }}&gt;</span>
                </ng-template>

                <span>&nbsp;</span>

                <span *ngIf="contact.last_name; else noLastNameTemplate">{{ contact.last_name }}</span>
                <ng-template #noLastNameTemplate>
                  <span style="opacity: 0.5">&lt;{{ 'no_surname' | intl }}&gt;</span>
                </ng-template>
              </div>
              <div matListItemLine>
                <span *ngIf="contact.email">{{ contact.email }}</span>
                <span *ngFor="let number of contact.telephone; let i = index">
                  <span *ngIf="i > 0 || contact.email">,&nbsp;</span>
                  <span>{{ number }}</span>
                </span>
              </div>
            </mat-list-item>
          </cdk-virtual-scroll-viewport>
        </mat-nav-list>
      </div>

      <ng-template #noContactsTemplate>
        <div class="no-contacts-container">
          <mat-icon>person_off</mat-icon>
          <span>{{ 'organization_has_no_contacts' | intl }}</span>
        </div>
      </ng-template>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">corporate_fare</mat-icon>
      @if (!visible) {
      <span>{{ 'subordinate_organizations' | intl }}</span
      >&nbsp; }
      <span *ngIf="countOrganizationChildren$ | async as count">({{ count }})</span>
    </ng-template>

    <ng-template matTabContent>
      <ng-container *ngIf="orgChildrensList.listStateR.value$ | async as orgsState" class="tab">
        <!-- <rcg-module-search
          class="search"
          [search_CAPTION]="'Išči...'"
          [debounceTime]="900"
          (searchPhrase)="searchOrgChildren($event)"
          [count]="(orgChildrensList.allCountR.value$ | async) ?? undefined"
        >
        </rcg-module-search> -->
        <rcg-virtual-list
          *ngIf="orgsState.data?.length; else noChilldreenTemplate"
          [loading]="orgsState.loading"
          [error]="orgsState.error"
          [paging]="orgsState.paging!"
          [itemSize]="100"
          [data]="orgsState.data"
          [itemTemplate]="organization"
          [wrapInCard]="false"
          (loadMore)="orgChildrensList.loadMore($event)"
        >
        </rcg-virtual-list>
        <ng-template #organization let-data="data">
          <mat-card *ngIf="data" (click)="openOrganization(data)" appearance="outlined" style="display: flex; width: 100%; height: 100%">
            <div style="display: flex; justify-content: space-between">
              <div style="font-size: 12px; font-weight: 500">{{ data.short_name ?? data.long_name ?? '' }}</div>
              <span style="font-size: 12px">{{ data?.id ?? '' }}</span>
            </div>
            <div style="display: flex; justify-content: space-between">
              <span>{{ data.street ?? '' }} {{ data.house_number ?? '' }}</span>
              <span>{{ data.postal_number ?? '' }} {{ data.postal_name ?? '' }}</span>
            </div>
            <div style="display: flex; justify-content: space-between">
              <span></span>
              <div>{{ data.country?.name ?? '' }}</div>
            </div>
          </mat-card>
        </ng-template>
      </ng-container>

      <ng-template #noChilldreenTemplate>
        <div class="no-contacts-container">
          <mat-icon>corporate_fare</mat-icon>
          <span>{{ 'no_subordinate_organizations' | intl }}</span>
        </div>
      </ng-template>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">devices</mat-icon>
      @if (!visible) {
      <span>{{ 'installed_equipment' | intl }}</span
      >&nbsp; }
      <span *ngIf="counInstalledEquipment$ | async as count">({{ count }})</span>
    </ng-template>

    <ng-template matTabContent>
      <ng-container *ngIf="installedEquipmentList.listStateR.value$ | async as equipmentState" class="tab">
        <rcg-virtual-list
          *ngIf="equipmentState.data?.length; else noDevicesTemplate"
          [loading]="equipmentState.loading"
          [error]="equipmentState.error"
          [paging]="equipmentState.paging!"
          [itemSize]="100"
          [data]="equipmentState.data"
          [itemTemplate]="devices"
          [wrapInCard]="false"
          (loadMore)="installedEquipmentList.loadMore($event)"
          [route]="'./detail'"
        >
        </rcg-virtual-list>
        <ng-template #devices let-data="data">
          <mat-card *ngIf="data" appearance="outlined" style="display: flex; width: 100%; height: 100%" (click)="openDevice(data)">
            <div style="display: flex; justify-content: space-between">
              <div style="font-size: 12px; font-weight: 500">{{ data.description ?? '' }}</div>
              <span style="font-size: 12px">{{ data?.product_id ?? '' }}</span>
            </div>
            <div style="display: flex; justify-content: space-between">
              <div>{{ data?.serial_no ?? '' }}</div>
            </div>
          </mat-card>
        </ng-template>
      </ng-container>
      <ng-template #noDevicesTemplate>
        <div class="no-contacts-container">
          <mat-icon>devices</mat-icon>
          <span>{{ 'no_equipment_installed' | intl }}</span>
        </div>
      </ng-template>
    </ng-template>
  </mat-tab>
</mat-tab-group>
