<div class="stack" style="width: calc(100% - 32px)">
  <div class="recovery-codes">
    @for (code of codes(); track $index) {
    <pre>{{ code }}</pre>
    }
  </div>

  <mat-spinner *ngIf="loading()" mode="indeterminate" [diameter]="64"></mat-spinner>
</div>

<button mat-raised-button color="primary" style="margin-top: 12px" [disabled]="loading()" (click)="downloadCodes()">
  <mat-icon>download</mat-icon>
  {{ 'download_recovery_codes' | intl }}
</button>

<span class="instructions">
  {{ 'mfa_recovery_code_instructions' | intl }}
</span>
