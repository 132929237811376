<ejs-datetimepicker
  [readonly]="props.readonly"
  [formControl]="formCtrl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [firstDayOfWeek]="firstDayOfWeek"
  [format]="field.props?.format"
  [locale]="sfLocale$ | async"
  (open)="popupOpen($event)"
>
</ejs-datetimepicker>
