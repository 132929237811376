<mat-form-field class="time-input-double-form-field" [ngStyle]="{ minWidth: this.defaultWidth, maxWidth: this.defaultWidth }">
  <mat-label *ngIf="autoOptions.labels?.fromLabel">{{ autoOptions.labels!.fromLabel }}</mat-label>
  <input
    #fromInput
    type="text"
    matInput
    [matAutocomplete]="fromAuto"
    (keyup)="onFromKeyup($event); trigger.closePanel()"
    [formControl]="fromFormControl"
    #trigger="matAutocompleteTrigger"
    [errorStateMatcher]="fromTimeErrMatcher"
    [readonly]="props.readonly"
    (click)="onTimeInputFocus($event)"
  />
  <mat-autocomplete #fromAutocomplete #fromAuto="matAutocomplete" (optionSelected)="onFromSelectChange($event)">
    <mat-option *ngFor="let time of fromOptions" [value]="time.value">
      {{ time.label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="time-input-double-form-field" [ngStyle]="{ minWidth: this.defaultWidth, maxWidth: this.defaultWidth }">
  <mat-label *ngIf="autoOptions.labels?.toLabel">{{ autoOptions.labels!.toLabel }}</mat-label>
  <input
    #toInput
    type="text"
    matInput
    [matAutocomplete]="toAuto"
    (keyup)="onToKeyup($event); t.closePanel()"
    [formControl]="toFormControl"
    #t="matAutocompleteTrigger"
    [readonly]="props.readonly"
    (click)="onTimeInputFocus($event)"
  />
  <mat-autocomplete #toAutocomplete #toAuto="matAutocomplete" (optionSelected)="onToSelectChange()">
    <mat-option *ngFor="let time of toOptions" [value]="time.value">
      {{ time.label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field
  *ngIf="autoOptions.displayWorkingHours"
  class="time-input-double-form-field"
  [ngStyle]="{ minWidth: this.defaultWidth, maxWidth: this.defaultWidth }"
>
  <mat-label *ngIf="autoOptions.labels?.workingHoursLabel">{{ autoOptions.labels!.workingHoursLabel }}</mat-label>
  <input matInput [ngModel]="getWorkingHours()" [formControl]="workingHoursFC" readonly />
</mat-form-field>
