import { gql } from 'apollo-angular';

export const searchIconsGql = gql`
  query IconsQuery($search: String = "") {
    data: global_material_icons(where: { _or: [{ id: { _ilike: $search } }, { name: { _ilike: $search } }] }) {
      name
      id
    }
  }
`;
