<mat-select
  [id]="id"
  [formControl]="formCtrl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
  [tabIndex]="props.tabindex"
  [required]="props.required ?? false"
  (selectionChange)="onChange($event)"
  [errorStateMatcher]="errorStateMatcher"
  [disableOptionCentering]="true"
  [typeaheadDebounceInterval]="1000"
>
  <ng-container *ngIf="selectOptions">
    <mat-option *ngFor="let item of selectOptions" [value]="item.value">
      {{ item.label }}
    </mat-option>
  </ng-container>
</mat-select>
<mat-error *ngIf="error">{{ error }}</mat-error>
