<div class="stack" style="width: calc(100% - 32px)">
  <div style="width: 100%; display: flex; align-items: flex-end; gap: 4px">
    <formly-form
      style="flex: 1"
      [style.opacity]="loading() ? '0.5' : '1'"
      [fields]="propsFormFields"
      [model]="propsFormModel()"
      (modelChange)="setProps.set($event)"
    ></formly-form>

    <button
      *ngVar="allowResendInSeconds() as arIs"
      mat-icon-button
      color="primary"
      [matTooltip]="'mfa_sms_send_code' | intl"
      [matBadge]="arIs ? arIs : ''"
      [disabled]="!!arIs"
      (click)="enroll()"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>

  <mat-spinner *ngIf="loading()" mode="indeterminate" [diameter]="64"></mat-spinner>
</div>

<span class="instructions">
  {{ 'mfa_sms_instructions' | intl }}
</span>

<rcg-otp-pin-input
  #pinInput
  [numDigits]="numDigits"
  [disabled]="digitsDisabled"
  (codeEntered)="enteredCode.set($event)"
></rcg-otp-pin-input>
