import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core/models';
import { IntlService } from '@rcg/intl';

interface CurrencyProps extends RcgFormlyFieldProps {
  currency?: string;
  displayCurrencySymbol?: boolean;
}

@Component({
  selector: 'rcg-currency-field',
  template: `
    <input
      #inputElement
      matInput
      type="text"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (input)="onInput($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
    />
  `,
  providers: [CurrencyPipe],
})
export class CurrencyFieldComponent extends RcgFieldType<number | null, CurrencyProps> implements OnInit, AfterViewInit {
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;

  // private locale: string = 'sl-SI';
  private decimalSeparator: string = ',';

  private currency: string = 'EUR';
  private displayCurrencySymbol: boolean = false;

  private readonly currencyPipe = inject(CurrencyPipe);
  private intlService = inject(IntlService);
  private readonly locale = toSignal(this.intlService.locale$);

  ngOnInit(): void {
    // props settings
    if (this.props.currency) {
      this.displayCurrencySymbol = !!this.props.currency;
    }
    if (this.props.displayCurrencySymbol) {
      this.displayCurrencySymbol = !!this.props.displayCurrencySymbol;
    }

    this.initializeValue();
  }

  ngAfterViewInit(): void {
    this.updateDisplayValue();
  }

  private initializeValue(): void {
    const initialValue = this.getValue();
    const numericValue = this.parseNumber(initialValue);
    if (!isNaN(numericValue)) {
      this.setValue(this.roundToTwoDecimals(numericValue), false);
    } else {
      this.setValue(0, false);
      console.warn('Currency field - Failed to parse initial value, defaulting to 0');
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart;
    let inputValue = input.value;

    // Convert period to comma if it's used as a decimal separator
    if (inputValue.includes('.') && !inputValue.includes(',')) {
      inputValue = inputValue.replace('.', ',');
    }

    const sanitizedValue = this.sanitizeInput(inputValue);
    const numericValue = this.parseNumber(sanitizedValue);

    if (!isNaN(numericValue)) {
      this.setValue(this.roundToTwoDecimals(numericValue), true);
      input.value = sanitizedValue;

      // Restore cursor position
      setTimeout(() => {
        input.setSelectionRange(cursorPosition, cursorPosition);
      });
    }
  }

  onFocus(): void {
    const numericValue = this.field.formControl.value;
    this.inputElement.nativeElement.value = this.formatEditableValue(numericValue);
  }

  onBlur(): void {
    this.updateDisplayValue();
  }

  private updateDisplayValue(): void {
    const numericValue = this.getValue();
    let formattedValue: string | null;

    if (this.displayCurrencySymbol) {
      formattedValue = this.currencyPipe.transform(numericValue, this.currency, 'symbol', '1.2-2', this.locale());
    } else {
      // Format without currency symbol
      formattedValue = this.currencyPipe.transform(numericValue, this.currency, '', '1.2-2', this.locale());
    }

    if (formattedValue !== null) {
      this.inputElement.nativeElement.value = formattedValue;
    }
  }

  private parseNumber(value: unknown): number {
    if (typeof value === 'string') {
      // Replace comma with dot for parsing
      return parseFloat(value.replace(',', '.').replace(/[^\d.-]/g, ''));
    }
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'object' && value !== null) {
      const objectValue = Object.values(value)[0];
      return this.parseNumber(objectValue);
    }
    return NaN;
  }

  private sanitizeInput(value: string): string {
    // Allow only digits, one decimal separator, and minus sign at the beginning
    const parts = value.split(this.decimalSeparator);
    if (parts.length > 2) {
      parts[1] = parts.slice(1).join(''); // Join all parts after the first decimal
    }
    parts[0] = parts[0].replace(/[^\d-]/g, '');
    if (parts[1]) {
      parts[1] = parts[1].replace(/\D/g, '').slice(0, 2); // Limit to 2 decimal places
    }
    return parts.join(this.decimalSeparator);
  }

  private formatEditableValue(value: number | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.toFixed(2).replace('.', this.decimalSeparator);
  }

  private roundToTwoDecimals(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  private setValue(value: number | null, emitEvent: boolean): void {
    this.field.formControl.setValue(value, { emitEvent: emitEvent });
  }

  private getValue(): number {
    return this.field.formControl.value ?? 0;
  }
}
