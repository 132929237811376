<input #fileUpload type="file" class="file-input" (change)="onFilesSelected($event)" [multiple]="multiple" />

<div class="container">
  <rcg-thumbnails-container
    [attachments]="attachments"
    [blobUrls]="blobUrls"
    [multiple]="multiple"
    [displayInline]="props.settings?.displayInline ?? false"
    (thumbnailClick)="onThumbnailClicked($event)"
  ></rcg-thumbnails-container>
  <div class="file-upload" [style.display]="props.settings?.hideFileUpload ? 'none' : ''">
    <button
      mat-mini-fab
      class="upload-btn"
      color="primary"
      [matTooltip]="'attach_file' | intl"
      [disabled]="isDisabledOrReadonly || (!multiple && attachments.length > 0)"
      (click)="uploadClick($event)"
    >
      <mat-icon class="upload-icon">attach_file</mat-icon>
    </button>

    @if (oneDriveAttachmentApp$ | async; as app) {
    <button
      mat-mini-fab
      class="upload-btn"
      *ngIf="isMobile || !app.mobile_only"
      color="primary"
      [matTooltip]="'download_file_from_onedrive' | intl"
      [disabled]="isDisabledOrReadonly || (!multiple && attachments.length > 0)"
      (click)="oneDriveDownloadClick($event, app)"
    >
      <img class="upload-icon" src="/assets/onedrive.icon.png" />
    </button>
    }
  </div>
</div>
<!-- accept="audio/*|video/*|image/*|MIME_type|text/plain|.pdf" -->
