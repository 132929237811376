@if (!props.hiddenButtons?.includes('add')) {
<button mat-icon-button [matMenuTriggerFor]="addMenu" [matTooltip]="'mfa_add_button_tooltip' | intl"><mat-icon>add</mat-icon></button>
}

<mat-menu #addMenu="matMenu">
  <button mat-menu-item (click)="addTotpMfa()">
    <mat-icon>pin</mat-icon>
    <span>{{ 'mfa_type_totp' | intl }}</span>
  </button>

  <button mat-menu-item (click)="addSmsMfa()">
    <mat-icon>textsms</mat-icon>
    <span>{{ 'mfa_type_sms' | intl }}</span>
  </button>

  <button mat-menu-item (click)="addRecoveryCodeMfa()">
    <mat-icon>sync_problem</mat-icon>
    <span>{{ 'mfa_type_recovery_code_plural' | intl }}</span>
  </button>

  <!-- TODO -->

  <!-- <button mat-menu-item (click)="addSecurityKeyMfa()">
    <mat-icon>key</mat-icon>
    <span>{{ 'mfa_type_security_key' | intl }}</span>
  </button> -->
</mat-menu>

@if (!props.hiddenButtons?.includes('modify')) {
<button mat-icon-button [matTooltip]="'mfa_modify_button_tooltip' | intl" (click)="modifyMfa()"><mat-icon>tune</mat-icon></button>
}
