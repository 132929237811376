import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  ViewChild,
  computed,
  signal,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UnInitMfaEnrollResponse } from '@npm-libs/auth-manager';
import { AuthService } from '@rcg/auth';
import { tr } from '@rcg/intl';
import { OtpPinInputComponent } from '@rcg/standalone/components';
import { MessageService } from '@rcg/standalone/services';
import { firstValueFrom } from 'rxjs';
import { AddMfaDialogComponent } from '../../add-mfa-dialog.component';

const authType = 'recovery/code';

@Component({
  selector: 'rcg-recovery-code-mfa-adder[dialogRef]',
  templateUrl: './recovery-code-adder.component.html',
  styleUrls: ['./recovery-code-adder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoveryCodeMfaAdderComponent implements OnInit {
  @Input() public dialogRef!: MatDialogRef<AddMfaDialogComponent, void>;

  @Output() afterAdded = new EventEmitter<void>();

  @ViewChild('pinInput') readonly pinInput!: OtpPinInputComponent;

  public readonly enrollResponse = signal<UnInitMfaEnrollResponse<typeof authType> | null>(null);

  public readonly loading = computed(() => this.enrollResponse() === null);

  public readonly hasCodes: Signal<boolean> = computed(() => !!this.enrollResponse()?.props?.codes);
  public readonly codes = computed(() => this.enrollResponse()?.props?.codes);

  public readonly enrollId: Signal<number | undefined> = computed(() => this.enrollResponse()?.id);

  constructor(private readonly authService: AuthService, private readonly msg: MessageService) {}

  ngOnInit(): void {
    this.enroll();
  }

  private async enroll() {
    this.enrollResponse.set(null);

    try {
      const res = await this.authService.mfaEnroll({
        type: authType,
      });

      this.enrollResponse.set(res);
      this.afterAdded.emit();
    } catch (error) {
      this.msg.showErrorSnackbar('Error', error);
    }
  }

  public async downloadCodes() {
    const text = this.codes()!
      .map((c, i) => `${i + 1}. ${c}`)
      .join('\n');

    const recoveryCodesTr = await firstValueFrom(tr('mfa_type_recovery_code_plural'));
    const userName = this.authService.user()!.fullName;
    const filename = `RcG MFA ${recoveryCodesTr} - ${userName}.txt`;

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
