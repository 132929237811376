<rcg-dialog
  [dialogRef]="dialogRef"
  icon="enhanced_encryption"
  [title]="'modify_mfa_dialog_title' | intl"
  [iconTooltip]="'modify_mfa_dialog_title' | intl"
  [disabledToolbarActions]="['minimize']"
>
  <div class="stack">
    <mat-list class="mfa-list">
      @for (mfa of userMfa(); track $index) {
      <mat-list-item>
        <mat-icon matListItemIcon style="color: var(--app-primary-color)">{{ mfaIconMap[mfa.type] }}</mat-icon>
        <div class="text-container">
          <div>
            <span class="mat-mdc-list-item-title mdc-list-item__primary-text">
              #{{ mfa.id }} <span style="opacity: 0.5">&mdash;</span> {{ mfaNameMap[mfa.type] | async }}
              <span style="opacity: 0.5">/</span> {{ mfa.created_at | date : 'short' }}
            </span>
            <span class="mat-mdc-list-item-line mdc-list-item__secondary-text">{{ mfa.description }}</span>
          </div>

          <button mat-icon-button (click)="deleteUserMfa(mfa)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </mat-list-item>

      <mat-divider></mat-divider>
      }
    </mat-list>

    @if (!userMfa()?.length) {
    <span class="no-registered-factors-caption">{{ 'no_registered_mfa_factors' | intl }}</span>
    }
  </div>
</rcg-dialog>
