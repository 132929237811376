import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { RcgFieldType } from '@rcg/core/models';
import { Subscription, startWith } from 'rxjs';

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid);
  }
}

@Component({
  selector: 'rcg-password-change-input',
  templateUrl: './password-change-input.component.html',
  styleUrls: ['./password-change-input.component.scss'],
})
export class PasswordChangeInputComponent extends RcgFieldType implements OnInit, OnDestroy {
  @ViewChild('pass1') pass1!: ElementRef<HTMLInputElement>;
  @ViewChild('pass2') pass2!: ElementRef<HTMLInputElement>;

  pass1FC = new UntypedFormControl();
  pass2FC = new UntypedFormControl();

  passErrMatcher = new PasswordErrorStateMatcher();

  hide = true;

  private valueSub?: Subscription;
  private statusSub?: Subscription;

  private get control() {
    return this.formControl as AbstractControl;
  }

  passValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.pass1 && this.pass2) {
        return this.pass1.nativeElement.value === this.pass2.nativeElement.value ? null : { invalidTime: control.value };
      } else {
        return null;
      }
    };
  }

  ngOnInit(): void {
    this.pass1FC.addValidators([this.passValidator()]);
    this.pass2FC.addValidators([this.passValidator()]);

    this.valueSub = this.control.valueChanges.subscribe((value) => {
      if (value === undefined) {
        this.pass1FC.setValue(null);
        this.pass2FC.setValue(null);
      }
    });

    this.statusSub = this.formControl.statusChanges.pipe(startWith(this.formControl.status)).subscribe((status) => {
      if (status === 'DISABLED') {
        this.pass1FC.disable();
        this.pass2FC.disable();
      } else {
        this.pass1FC.enable();
        this.pass2FC.enable();
      }
    });
  }

  override ngOnDestroy(): void {
    this.valueSub?.unsubscribe();
    this.statusSub?.unsubscribe();

    super.ngOnDestroy();
  }

  onPass1Keyup(): void {
    this.pass2FC.updateValueAndValidity();
    this.updateFieldValue();
  }

  onPass2Keyup(): void {
    this.pass1FC.updateValueAndValidity();
    this.updateFieldValue();
  }

  updateFieldValue(): void {
    if (this.pass1FC.valid && this.pass2FC.valid && this.pass1.nativeElement.value === this.pass2.nativeElement.value) {
      this.value = this.pass1.nativeElement.value;
      this.control.setValue(this.pass1.nativeElement.value);
    } else {
      this.value = null;
      this.control.setValue(null);
    }
  }
}
