<ng-container *ngIf="loading; then loadingTemplate; else error ? errorTemplate : okTemplate"></ng-container>

<ng-template #loadingTemplate>
  @if (loadedAttachmentsFraction === 1) {
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  } @else {
  <mat-progress-bar
    mode="buffer"
    [bufferValue]="loadedAttachmentsFraction * 100"
    [matTooltip]="('uploading_attachments' | intl) + ': ' + (loadedAttachmentsFraction * 100 | number : '1.2-2') + '%'"
  ></mat-progress-bar>
  }
</ng-template>

<ng-template #errorTemplate>
  <h3 style="color: red">{{ 'error' | intl }}</h3>
  <pre style="white-space: pre-wrap">{{ error }}</pre>
</ng-template>

<ng-template #okTemplate>
  <div class="container">
    <ng-container *ngIf="value?.eml_id && emlAttachments && emlAttachments.length > 0">
      <rcg-thumbnails-container
        [attachments]="emlAttachments"
        [blobUrls]="emlAttachmentsThumbnailBlobUrls"
        (thumbnailClick)="onAttachmentThumbnailClicked($event)"
      ></rcg-thumbnails-container>
    </ng-container>

    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>

    <rcg-html-editor
      #editor
      readonlyIconColor="darkorange"
      [class.editing]="editor.editorMode === 'edit'"
      [value]="value"
      [emlOriginalHtml]="emlOriginalHtml"
      [editorMode]="editorMode"
      [readonly]="props.readonly || (props.disabled ?? false) || loadedAttachmentsFraction !== 1"
      [editOnClick]="props.settings?.editOnClick ?? false"
      [stopEditOnUnfocus]="props.settings?.stopEditOnUnfocus ?? false"
      [enableContactsInput]="props.settings?.contactsInput ?? false"
      [formatPastedLinks]="props.settings?.formatPastedLinks ?? true"
      (valueUpdating)="valueUpdating($event)"
      (valueChanged)="valueChanged($event)"
      (editModeChanged)="editModeChanged()"
      (contactAdded)="contactAdded($event)"
      (inlineAttachmentsAdded)="inlineAttachmentsAdded($event)"
    >
      <ng-container extraButtons>
        <button
          *ngIf="props.settings?.showCreateKnowledgeBaseButton ?? true"
          [matTooltip]="'create_knowledgebase' | intl"
          matTooltipPosition="above"
          mat-icon-button
          (click)="createKnowledgeBase($event)"
        >
          <mat-icon style="font-size: 20px; padding-top: 2px">note_add</mat-icon>
        </button>

        <button
          *ngIf="props.settings?.listSplit && splitLists.length"
          [matTooltip]="'split_list_to_events' | intl"
          matTooltipPosition="above"
          mat-icon-button
          (click)="showSplitListDialog($event)"
        >
          <mat-icon style="font-size: 20px; padding-top: 2px">checklist</mat-icon>
        </button>
      </ng-container>
    </rcg-html-editor>
  </div>
</ng-template>
