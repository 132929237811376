import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RcgFieldType } from '@rcg/core/models';
import { TimeSpentComponent } from './time-spent/time-spent.component';

@Component({
  selector: 'rcg-time-spent-field',
  templateUrl: './time-spent-field.component.html',
  styleUrls: ['./time-spent-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSpentFieldComponent extends RcgFieldType implements OnDestroy {
  @ViewChild('timeField') timeField?: TimeSpentComponent;

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  override get shouldLabelFloat() {
    return this.timeField?.shouldLabelFloat ?? false;
  }
}
