import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import * as Get from '@npm-libs/ng-getx';
import { ContainerViewComponent, DataConnectorConfig, ViewConfig } from '@npm-libs/ng-templater';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs';

export interface TemplaterTemplateProps extends FormlyFieldProps {
  config: ViewConfig<string, unknown> | ViewConfig<string, unknown>[];
  dataConnectorConfigs?: DataConnectorConfig[];
}

export type TemplaterTemplateConfig = FieldTypeConfig<TemplaterTemplateProps>;

@Get.NgAutoDispose
@Component({
  selector: 'rcg-field-templater-template',
  templateUrl: './templater-template.component.html',
  styleUrls: ['./templater-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ContainerViewComponent],
})
export class TemplaterTemplateComponent extends FieldType<TemplaterTemplateConfig> implements AfterViewInit {
  @ViewChild('container') container!: ContainerViewComponent;

  readonly modelR = new Get.Rx<{ [x: string]: unknown }>({}, [distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))]);

  ngAfterViewInit(): void {
    this.modelR.subscribeTo(
      this.form.valueChanges.pipe(
        startWith(null),
        map(() => this.model),
      ),
    );

    this.modelR.subscribeTo(
      this.container.runtimeData$.pipe(
        map(({ model }) => (model ?? {}) as Record<string, unknown>),
        filter((m) => Object.keys(m).length > 0),
      ),
    );

    this.modelR.value$.pipe(filter((m) => Object.keys(m).length > 0)).subscribe((model) => {
      this.container.patchRuntimeData({ model });

      for (const [k, c] of Object.entries(this.form.controls)) {
        c.setValue(model[k]);
      }
    });
  }
}
