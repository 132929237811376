<div class="time-spent-container">
  <input
    type="number"
    #hours
    [id]="hoursUniqueId"
    class="time-spent-input-element"
    aria-label="hours"
    min="0"
    max="9999"
    size="4"
    [step]="hoursStep"
    [disabled]="disabled"
    (change)="fieldChanged()"
    (keyup)="fieldChanged()"
  />
  <span class="time-spent-input-spacer">:</span>
  <input
    type="number"
    #minutes
    [id]="minutesUniqueId"
    class="time-spent-input-element"
    aria-label="minutes"
    min="0"
    max="59"
    size="2"
    [step]="minutesStep"
    [disabled]="disabled"
    (change)="fieldChanged()"
    (keyup)="fieldChanged()"
    rcgMinutes
  />
</div>
