<div class="input">
  <input
    matInput
    [id]="id"
    [formlyAttributes]="field"
    [formControl]="formCtrl"
    type="text"
    [required]="props.required ?? false"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
    [readonly]="props.readonly"
    [matAutocomplete]="auto"
  />
  @for(action of actions | visibleFieldActions: value:field;track $index){
  <mat-icon [matTooltip]="(action.tooltip | tooltip | async) ?? ''" class="field-icon-btn" (click)="executeAction(action)">{{
    action.icon
  }}</mat-icon>
  }

  <ng-container
    *ngTemplateOutlet="
      props?.readonly === true || props?.disabled === true ? noIconTemplate : formControl.value ? valueIconTemplate : noValueIconTemplate
    "
  ></ng-container>
</div>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="dataDisplayFn">
  <ng-container *ngIf="isLoading"> <mat-progress-bar mode="indeterminate"> </mat-progress-bar></ng-container>
  <mat-option disabled *ngIf="showNoData"> {{ 'no_data' | intl }} </mat-option>
  <ng-container *ngIf="!showNoData">
    <mat-option
      *ngFor="let item of optionsData"
      [value]="item"
      [disabled]="isLoading"
      style="height: 50px; line-height: 20px; margin-bottom: 1px"
    >
      <span [style.color]="item.color?.textColor">{{ item.label }}</span>
      <ng-container *ngIf="item.description">
        <br />
        @if (isString(item.description)) {
        <span [style.color]="item.color?.textColor ?? 'gray'" style="font-size: 12px">{{ item.description }}</span>
        } @else if (isArray(item.description)) {
        <ng-container *ngFor="let item of descriptionsArray(item.description)">
          <span [ngStyle]="item.style ?? { color: 'gray' }" style="font-size: 12px">{{ item.label }}</span>
        </ng-container>
        }
      </ng-container>
    </mat-option>
  </ng-container>
</mat-autocomplete>
<ng-container *ngIf="errorMessage">
  <span style="color: red; font-size: 10px">Napaka: {{ errorMessage }}</span>
</ng-container>

<ng-template #valueIconTemplate>
  <mat-icon class="field-icon-btn" *ngIf="acSettings?.editButton && this.formControl.valid" (click)="openEditDialog()">edit</mat-icon>
  <mat-icon class="field-icon-btn" (click)="clear()">close</mat-icon>
</ng-template>

<ng-template #noValueIconTemplate>
  <ng-container *ngIf="acSettings?.addContact?.addNewFormName; then addNewIconTemplate"></ng-container>
  <ng-container *ngIf="acSettings?.assignSelfQuery; then assignSelfIconTemplate"></ng-container>
  <ng-container *ngIf="!acSettings?.assignSelfQuery && !acSettings?.addContact?.addNewFormName; then noIconTemplate"></ng-container>
</ng-template>

<ng-template #addNewIconTemplate>
  <mat-icon class="field-icon-btn" (click)="addNewContact()">{{ acSettings.addContact?.addNewIcon ?? 'add' }}</mat-icon>
</ng-template>

<ng-template #assignSelfIconTemplate>
  <mat-icon class="field-icon-btn" [ngClass]="{ 'assign-self-warn': assignSelfWarn }" (click)="assignSelf()">person_outline</mat-icon>
</ng-template>

<ng-template #noIconTemplate>
  <div style="height: 24px"></div>
</ng-template>
