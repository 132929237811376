@if(time) {
<kendo-label text="{{ props.label ?? 'Time' }}{{ props.required ? ' *' : '' }}" style="display: flex; flex-direction: column">
  <kendo-timepicker
    [format]="props.format === 'seconds' ? 'HH:mm:ss' : 'HH:mm'"
    [value]="time"
    [fillMode]="'flat'"
    [size]="'small'"
    (valueChange)="onChange($event)"
  ></kendo-timepicker>
</kendo-label>

}
