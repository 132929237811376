<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  #tabGroup
  [headerPosition]="props.headerPosition ?? 'above'"
  [backgroundColor]="props.backgroundColor"
  [color]="props.color ?? ''"
  [selectedIndex]="props.selectedIndex"
  (selectedTabChange)="realignInkBars(); setSize()"
>
  <mat-tab *ngFor="let f of field.fieldGroup" [disabled]="f?.props?.disabled">
    <ng-container *ngIf="!f?.props?.hidden">
      <ng-template mat-tab-label>
        <mat-icon *ngIf="f?.props?.icon" class="tab-icon">{{ f.props?.icon }}</mat-icon>
        {{ f?.props?.label ?? (f?.props?.icon ? '' : '<?>') }}
      </ng-template>
    </ng-container>
    <ng-template matTabContent>
      <formly-field [field]="f"></formly-field>
    </ng-template>
  </mat-tab>
</mat-tab-group>
