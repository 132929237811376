import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@rcg/core/models';
import { ACSettings } from '../fields/autocomplete/models/ac-settings';

@Pipe({
  name: 'chipsTooltip',
  pure: true,
})
export class ChipsTooltipPipe implements PipeTransform {
  transform(select: SelectOption | undefined, acSettings: ACSettings): string {
    if (!select?.value) return '';

    if (acSettings?.chipsTooltip && typeof acSettings.chipsTooltip === 'function') {
      return acSettings.chipsTooltip(select) ?? '';
    }
    return select.value?.toString() ?? '';
  }
}
