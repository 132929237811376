import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { RcgFieldType, SelectOption } from '@rcg/core/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rcg-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent extends RcgFieldType implements OnInit, OnDestroy {
  private dependentFieldSubscription?: Subscription;

  constructor() {
    super();
  }

  ngOnInit() {
    this.dependentFieldSubscription?.unsubscribe();

    const listenFieldKey: string | null =
      this.to['settings'] && this.to['settings']['listenFieldKey'] && typeof this.to['settings']['listenFieldKey'] === 'string'
        ? this.to['settings']['listenFieldKey'].trim()
        : null;

    this.value = this.getInitalValue(listenFieldKey);

    // subscribe to dependent field changes
    if (listenFieldKey) {
      const depFieldControl = this.form.get(listenFieldKey);
      if (!depFieldControl) {
        return;
      }

      this.dependentFieldSubscription = depFieldControl!.valueChanges.subscribe((data) => {
        this.value = this.getPhoneNumbers(data);
      });
    }
  }

  override ngOnDestroy() {
    this.dependentFieldSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  override get shouldLabelFloat() {
    return true;
  }

  onFocusIn() {
    if (!this.focused) {
      this.field.focus = true;
      this.stateChanges.next();
    }
  }

  onFocusOut() {
    this.field.focus = false;
    this.stateChanges.next();
  }

  callNumber() {
    // todo call number
  }

  private getInitalValue(listenFieldKey: string | null) {
    if (listenFieldKey) {
      const data = this.model[listenFieldKey];
      return this.getPhoneNumbers(data);
    }
    return (this.value = this.value ?? null);
  }

  private getPhoneNumbers(data: unknown): string | null {
    if (!data || typeof data !== 'object') {
      return null;
    }
    const phoneNumbers = ((data as SelectOption)?.data as { telephone?: string[] | null | undefined })?.telephone;

    if (!phoneNumbers || phoneNumbers.length === 0) {
      return null;
    }

    return phoneNumbers.length > 1 ? phoneNumbers.slice(0, -1).join(',  ') + ',  ' + phoneNumbers.slice(-1) : phoneNumbers[0].trim();
  }
}
