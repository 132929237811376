<ng-container>
  <mat-form-field class="example-full-width">
    <mat-label>{{ props.label ?? 'Ikona' }}</mat-label>
    <input
      type="text"
      [placeholder]="props.placeholder ?? 'Ikona'"
      aria-label="Number"
      matInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
    />
    <mat-icon matSuffix>{{ formControl.value }}</mat-icon>
    <mat-autocomplete #auto="matAutocomplete">
      <!-- <rcg-virtual-list
        [ngStyle]="{ height: height }"
        style="margin-top: 8px"
        *ngIf="iconsListService.listStateR.value$ | async as state"
        [loading]="state.loading"
        [error]="state.error"
        [paging]="state.paging!"
        [data]="state.data"
        [itemTemplate]="icon"
        [itemSize]="25"
        [wrapInCard]="false"
        (loadMore)="iconsListService.loadMore($event)"
      ></rcg-virtual-list> -->
      <cdk-virtual-scroll-viewport [ngStyle]="{ height: height }" itemSize="25">
        <mat-option *cdkVirtualFor="let option of filteredIconOptions" [value]="option.id" class="autocomplete-item">
          {{ option.name }}
          <mat-icon>{{ option.id }}</mat-icon>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
