<div class="edit-container">
  <mat-icon [matTooltip]="'open_editor' | intl" matTooltipPosition="below" class="edit-icon" (click)="openHtmlEditor()">edit </mat-icon>
</div>
<div (focusin)="onFocusIn()" (focusout)="onFocusOut()">
  <textarea
    matInput
    [id]="id"
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="5"
    textarea
    [formControl]="formCtrl"
    [formlyAttributes]="field"
    type="text"
    [required]="props.required ?? false"
    [readonly]="props.readonly"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
  ></textarea>
</div>
