import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MfaAuthType } from '@npm-libs/auth-manager';

export interface AddMfaDialogConfig {
  type?: MfaAuthType;
  afterAdded?: () => void;
}

@Component({
  selector: 'rcg-add-mfa-dialog',
  templateUrl: './add-mfa-dialog.component.html',
  styleUrls: ['./add-mfa-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMfaDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddMfaDialogComponent, void>,
    @Inject(MAT_DIALOG_DATA) public dialogConfig?: AddMfaDialogConfig,
  ) {}

  public afterAdded() {
    this.dialogConfig?.afterAdded?.();
  }
}
