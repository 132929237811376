import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, catchError, isObservable, of, tap } from 'rxjs';
import { SelecFieldtProps, SelectOption } from '../select/select.component';

@Component({
  selector: 'rcg-multiselect-field',
  templateUrl: './multiselect-field.component.html',
  styleUrl: './multiselect-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectFieldComponent extends FieldType<FieldTypeConfig<SelecFieldtProps>> implements OnInit {
  optionsValues = signal<SelectOption[]>([]);

  private destroyRef = inject(DestroyRef);

  error = signal<string | null>(null);

  ngOnInit(): void {
    this.value = this.getInitalValue();

    if (this.props.options) {
      this.optionsValues.set((this.props.options as SelectOption[]) ?? []);
      return;
    }

    const optionsFunc = this.props.optionsFunc;

    if (optionsFunc && typeof optionsFunc === 'function') {
      const value = (optionsFunc as (model: Record<string, unknown>) => SelectOption[] | Observable<SelectOption[]>)(this.field.model);

      if (isObservable(value)) {
        value
          .pipe(
            tap(() => this.error.set(null)),
            catchError((error) => {
              this.error.set('Error: ' + error?.message ?? '');
              return of([]);
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe((options) => {
            this.optionsValues.set((options as SelectOption[] | undefined) ?? []);
          });
      } else {
        this.optionsValues.set(value ?? []);
      }
    }
  }

  clearValue() {
    this.value = [];
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  private getInitalValue() {
    if (this.value && this.value.length > 0) {
      return this.value;
    }

    // default value only if insert mode
    if (this.formState.query_variable && !this.model?.[this.formState.query_variable] && this.field.defaultValue) {
      const defaultValue = typeof this.field.defaultValue === 'function' ? this.field.defaultValue() : this.field.defaultValue;
      return defaultValue ?? [];
    }
    return [];
  }
}
