import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RangePipe } from './range.pipe';

@Component({
  selector: 'rcg-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RatingComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, MatIconModule, RangePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements ControlValueAccessor, Validator {
  @Input() rating = 0;

  @Input() maxRating = 5;

  @Input() tabindex?: number | string;

  @Input() ariaValueText(current: number, max: number) {
    return `${current} out of ${max}`;
  }

  @Input() readonly = false;

  @Output() blurChange = new EventEmitter<void>();

  disabled = false;

  onChange: (value: unknown) => void = () => {};

  onTouched = () => {};

  handleBlur() {
    this.onTouched();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return true;
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  reset(): void {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: number) {
    this.rating = value;
  }

  rate(rating: number) {
    if (this.readonly || this.disabled) return;

    if (this.rating === rating) {
      this.rating = 0; // clear on duble click
    } else {
      this.rating = rating;
    }
    this.onChange(this.rating);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.hasRequiredField(control) && !control.value) {
      return { required: true };
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnValidatorChange?(fn: () => void): void {
    // not used, unnecessary
  }

  private hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if (abstractControl && abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  };
}
