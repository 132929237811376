import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ControlValueAccessor, NG_ASYNC_VALIDATORS, NgControl, ValidationErrors } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject, Subscription, of } from 'rxjs';

export interface ITimeSpentFormOutput {
  hours: number;
  minutes: number;
}

@Component({
  selector: 'rcg-time-spent',
  templateUrl: './time-spent.component.html',
  styleUrls: ['./time-spent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TimeSpentComponent,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => TimeSpentComponent),
      multi: true,
    },
  ],
})
export class TimeSpentComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<number> {
  // tslint:disable: no-inferrable-types
  static nextId = 0;

  hoursUniqueId = '';
  minutesUniqueId = '';

  @HostBinding('class.time-spent-floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @HostBinding('attr.aria-describedby') describedBy = '';
  @HostBinding('id')
  id = `rcg-time-spent-${TimeSpentComponent.nextId++}`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ngControl: any;

  autofilled = false;
  controlType = 'rcg-time-spent';
  focused = false;
  stateChanges: Subject<void> = new Subject<void>();

  @Input('disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (value) {
      this._disabled = value;
    }
  }
  private _disabled = false;

  get empty(): boolean {
    const controlValue: number | null = this._currentFieldValue;

    if (controlValue === null) {
      return true;
    } else {
      return false;
    }
  }

  get errorState() {
    if (this.ngControl) {
      return this.ngControl.errors;
    } else {
      return null;
    }
  }

  @Input('placeholder')
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder = '';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
    this._changeDetectionRef.markForCheck();
  }
  private _required = false;

  @Input()
  get value(): number | null {
    return this._currentFieldValue;
  }
  set value(value: number | null) {
    this.stateChanges.next();
    this._changeDetectionRef.markForCheck();
  }

  @Input() hoursStep = 1;
  @Input() minutesStep = 1;

  private _validator: AsyncValidatorFn;

  private _inputValueAccessor: { value: number | null } = { value: null };
  private _initialValue: ITimeSpentFormOutput | null = null;
  private _currentFieldValue: number | null = null;
  private _unsubscribe: Subject<void> = new Subject<void>();
  private fmSubs?: Subscription;
  private afmSubs?: Subscription;

  @ViewChild('hours', { static: false }) _hoursInputElement!: ElementRef;
  @ViewChild('minutes', { static: false }) _minutesInputElement!: ElementRef;

  constructor(
    protected _elementRef: ElementRef<HTMLInputElement>,
    protected _platform: Platform,
    private _autofillMonitor: AutofillMonitor,
    private _focusMonitor: FocusMonitor,
    private _injector: Injector,
    private _changeDetectionRef: ChangeDetectorRef,
  ) {
    this.hoursUniqueId = Math.random().toString(36).substring(7);
    this.minutesUniqueId = Math.random().toString(36).substring(7);
    this._validator = this._generateAsyncValidator();

    // Attach focus monitor
    if (this.fmSubs) {
      this.fmSubs.unsubscribe();
    }
    this.fmSubs = this._focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        // Mark control as touched
        this._onTouchedFn();
      }

      this.focused = !!origin;

      this.stateChanges.next();
      this._changeDetectionRef.markForCheck();
    });
  }

  ngOnInit() {
    this.ngControl = this._injector.get(NgControl);
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    if (this._platform.isBrowser) {
      if (this.afmSubs) {
        this.afmSubs.unsubscribe();
      }
      this.afmSubs = this._autofillMonitor.monitor(this._elementRef.nativeElement).subscribe((event) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }
  }

  ngAfterViewInit() {
    if (this._initialValue) {
      this._populateNativeElement(this._initialValue);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    if (this._platform.isBrowser) {
      this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
    }

    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  fieldChanged(): void {
    // Get latest values
    const hours: number = this._hoursInputElement.nativeElement.value;
    const minutes: number = this._minutesInputElement.nativeElement.value;

    // Transform to seconds
    const currentValue: number | null = this._transformToSeconds({
      hours,
      minutes,
    });

    // Write value
    this.writeValue(currentValue);

    // Mark control as changed and touched
    this._onChangeFn(currentValue);
    this._onTouchedFn();
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: Function): void {
    this._onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouchedFn = fn;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;

    this.stateChanges.next();

    this._changeDetectionRef.markForCheck();
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> | Promise<ValidationErrors | null> {
    return this._validator(control);
  }

  writeValue(value: number | null): void {
    const typeOfValue = typeof value;
    if (value && typeOfValue !== 'number') {
      console.warn(
        `Provided value is not valid. Expected value type is number, provided was value ${value} which is of type ${typeOfValue}.`,
      );

      return;
    }

    const transformedValue: ITimeSpentFormOutput | null = this._transformToSpentTime(value);

    // Set initial value
    if (!this._initialValue) {
      this._initialValue = transformedValue;
    }

    // Set current control value
    this._currentFieldValue = value;
    this.value = value;

    // Patch native element
    this._populateNativeElement(transformedValue);
    this._changeDetectionRef.markForCheck();
  }

  private _generateAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.length || control.disabled) {
        return of({});
      }

      const errors: ValidationErrors = {
        ...control.errors,
      };

      return of(errors);
    };
  }

  /* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
  private _onChangeFn: Function = (_: unknown) => {};
  private _onTouchedFn = () => {};
  /* eslint-enable @typescript-eslint/ban-types, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */

  private _populateNativeElement(value: ITimeSpentFormOutput | null) {
    // Patch native element
    if (value && this._hoursInputElement && this._minutesInputElement) {
      this._hoursInputElement.nativeElement.value = value.hours;
      this._minutesInputElement.nativeElement.value = value.minutes;
    }

    if (value === null && this._hoursInputElement && this._minutesInputElement) {
      this._hoursInputElement.nativeElement.value = null;
      this._minutesInputElement.nativeElement.value = null;
    }
  }

  private _transformToSeconds(value: ITimeSpentFormOutput | null): number | null {
    // If none of the values is set then return null to ensure, required validator works
    if (!value?.hours && !value?.minutes) {
      return null;
    }

    const hours: number = value.hours ? value.hours : 0;
    const minutes: number = value.minutes ? value.minutes : 0;

    const hoursToSeconds: number = hours * 3600;
    const minutesToSeconds: number = minutes * 60;

    return hoursToSeconds + minutesToSeconds;
  }

  private _transformToSpentTime(value: number | null): ITimeSpentFormOutput | null {
    if (!value) {
      return null;
    }

    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);

    return {
      hours,
      minutes,
    };
  }
}
