import { FormlyFieldConfig } from '@ngx-formly/core';
import { tr } from '@rcg/intl';

export const propsFormFields: FormlyFieldConfig[] = [
  {
    key: 'phoneNumber',
    type: 'phoneChips',
    props: {
      maxNumbers: 1,
      validatePhone: true,
    },
    expressions: {
      'props.disabled': 'model.loading',
      'props.label': tr('mfa_sms_otp_prop_phone_number'),
    },
  },
];
