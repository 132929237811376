<div class="input">
  <input
    matInput
    [id]="id"
    [formlyAttributes]="field"
    [formControl]="formCtrl"
    type="text"
    [required]="props.required ?? false"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
    [readonly]="props.readonly"
    [matAutocomplete]="auto"
  />
  <ng-container *ngIf="formControl.value && props?.readonly !== true && props?.disabled !== true">
    <mat-icon class="field-icon-btn" (click)="clear()">close</mat-icon>
  </ng-container>

  @for(action of actions | visibleFieldActions: value:field;track $index){
  <mat-icon [matTooltip]="(action.tooltip | tooltip | async) ?? ''" class="field-icon-btn" (click)="executeAction(action)">{{
    action.icon
  }}</mat-icon>
  }
</div>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectedValue($event)" [panelWidth]="'auto'" [displayWith]="dataDisplayFn">
  <ng-container *ngIf="isLoading"> <mat-progress-bar mode="indeterminate"> </mat-progress-bar></ng-container>
  <mat-option *ngFor="let item of optionsData" [value]="item" [disabled]="isLoading" style="height: 50px; line-height: 20px">
    <ng-container *ngIf="!isLoading">
      <span>{{ item.label }}</span>
      <ng-container *ngIf="item.description">
        <br />
        <span style="color: gray; font-size: 12px">{{ item.description }}</span>
      </ng-container>
    </ng-container>
  </mat-option>
</mat-autocomplete>
<ng-container *ngIf="errorMessage">
  <span style="color: red; font-size: 10px">{{ 'error' | intl }}: {{ errorMessage }}</span>
</ng-container>
