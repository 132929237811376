import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'rcg-formly-debug',
  templateUrl: './formly-debug.component.html',
  styleUrls: ['./formly-debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyDebugComponent {
  @Input() form!: UntypedFormGroup;
  @Input() model!: Record<string, unknown>;
}
