<mat-form-field [style.width.%]="100">
  <mat-label *ngIf="props?.label">{{ props!.label }} {{ props!.required ? '*' : '' }}</mat-label>

  <mat-chip-grid #chipGrid>
    <mat-chip-row
      *ngFor="let item of formControl.value; let i = index"
      [removable]="true"
      (removed)="remove(i)"
      (click)="chipClicked(item)"
    >
      @if (props.settings?.chipTemplate) {
      <templater-view--container
        [config]="{
          name: 'container',
          slots: {
            child: props.settings!.chipTemplate
          }
        }"
        [context$]="of({item})"
      ></templater-view--container>
      } @else {
      {{ item }}
      }

      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>

    @if (props.settings?.autocomplete) {
    <input
      #input
      [matAutocomplete]="auto"
      [placeholder]="props.placeholder ?? ''"
      [matChipInputFor]="chipGrid"
      [readOnly]="props.readonly"
      [disabled]="props.disabled"
      (keyup)="searchAutocomplete(input.value)"
      (matChipInputTokenEnd)="add($event)"
      [required]="props.required ?? false"
    />

    <mat-autocomplete #auto="matAutocomplete" [hideSingleSelectionIndicator]="true" (optionSelected)="autocompleteSelected($event, input)">
      <mat-option *ngIf="autocompleteLoading" class="rcg-chips-field-loading-option" disabled>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-option>

      @if ( props.settings?.autocomplete?.optionTemplate ) { @for (option of autocompleteOptions$ | async; track option) {
      <mat-option class="rcg-chips-field-templated-option" [value]="option">
        <templater-view--container
          [config]="{
            name: 'container',
            slots: {
              child: props.settings!.autocomplete!.optionTemplate
            }
          }"
          [context$]="of({option})"
        ></templater-view--container>
      </mat-option>
      } @empty {
      <mat-option disabled *ngIf="!autocompleteLoading">{{ 'no_data' | intl }}</mat-option>
      } } @else { @for (option of autocompleteOptions$ | async; track option) {
      <mat-option [value]="option">{{ option }}</mat-option>
      } @empty {
      <mat-option disabled *ngIf="!autocompleteLoading">{{ 'no_data' | intl }}</mat-option>
      } }
    </mat-autocomplete>
    } @else {
    <input
      [placeholder]="props.placeholder ?? ''"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      [readOnly]="props.readonly"
      [disabled]="props.disabled"
      (matChipInputTokenEnd)="add($event)"
      [required]="props.required ?? false"
    />
    }
  </mat-chip-grid>

  <mat-error *ngFor="let k of errorKeys">
    {{field.validation?.messages?.[k] || k}}
  </mat-error>
</mat-form-field>
