import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

export interface HeadingFieldProps extends FormlyFieldProps {
  headingType?: `h${IntRange<1, 7>}` | 'pre' | 'p';
}

export type HeadingFieldConfig = FieldTypeConfig<HeadingFieldProps>;

@Component({
  selector: 'rcg-heading-field',
  templateUrl: './heading-field.component.html',
  styleUrls: ['./heading-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingFieldComponent extends FieldType<HeadingFieldConfig> {}
