import { Injectable } from '@angular/core';
import {
  Action,
  ActionRegistration,
  ActionSingleConfig,
  DataReferenceConfig,
  DataReferenceContext,
  actionRegistry,
  parseDataReference,
} from '@npm-libs/ng-templater';
import { IFormDialogConfig } from '@rcg/core/models';
import { firstValueFrom } from 'rxjs';
import { FormDialogService } from './services';

export interface OpenFormActionConfig {
  openForm: {
    [x in keyof IFormDialogConfig]: DataReferenceConfig;
  };
}

class OpenFormAction extends Action<OpenFormActionConfig> {
  constructor(
    config: OpenFormActionConfig,
    patchRuntimeData: (data: { [x: string]: unknown }) => void,
    private readonly formDialogService: FormDialogService,
  ) {
    super(config, patchRuntimeData);
  }

  async execute(context: DataReferenceContext): Promise<void> {
    const dialogConfig = Object.fromEntries(
      await Promise.all(
        Object.entries(this.config.openForm).map(async ([k, v]) => [k, await firstValueFrom(parseDataReference(context, v))] as const),
      ),
    ) as unknown as IFormDialogConfig;

    this.formDialogService.openForm(dialogConfig);
  }
}

function getOpenFormActionRegistration(formDialogService: FormDialogService): ActionRegistration {
  const registration: ActionRegistration = (config, patchRuntimeData) => {
    if ('openForm' in config) {
      return new OpenFormAction(
        config as OpenFormActionConfig,
        patchRuntimeData,
        formDialogService,
      ) as unknown as Action<ActionSingleConfig>;
    }

    return undefined;
  };

  return registration;
}

@Injectable({
  providedIn: 'root',
})
export class OpenFormActionRegistrationService {
  constructor(formDialogService: FormDialogService) {
    actionRegistry.push(getOpenFormActionRegistration(formDialogService));
  }
}
