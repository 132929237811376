<div class="calendar">
  <input
    [formControl]="formCtrl"
    [formlyAttributes]="field"
    matInput
    [matDatepicker]="picker"
    (dateChange)="onDdateChange('change', $event)"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
