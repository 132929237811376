import { NumberInput } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatTabGroup, MatTabHeaderPosition } from '@angular/material/tabs';
import { RcgFieldType, RcgFormlyFieldProps } from '@rcg/core/models';
import * as dot from 'dot-object';
import { isObservable } from 'rxjs';

const instances: RcgFormTabsComponent[] = [];

interface FormTabsProps {
  headerPosition?: MatTabHeaderPosition;
  backgroundColor?: ThemePalette;
  color?: ThemePalette;
  selectedIndex?: NumberInput;
}

@Component({
  selector: 'rcg-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss'],
})
export class RcgFormTabsComponent extends RcgFieldType<unknown, RcgFormlyFieldProps<FormTabsProps>> implements AfterViewInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup?: MatTabGroup;

  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  private resizeObserver?: ResizeObserver;

  private _destructors: (() => void)[] = [];

  ngAfterViewInit(): void {
    instances.push(this);

    this.setSize();

    this.resizeObserver = new ResizeObserver(() => {
      setTimeout(() => this.setSize());
    });

    this.resizeObserver.observe(document.body);

    for (const field of this.field.fieldGroup ?? []) {
      for (const [k, v] of Object.entries(field.expressions ?? {})) {
        if (!isObservable(v)) {
          console.warn(`[${RcgFormTabsComponent}]`, 'Unsupported tab expression:', { k, v });
          continue;
        }

        const expressionSub = v.subscribe((val) => dot.set(k, val, field));
        this._destructors.push(() => expressionSub.unsubscribe());
      }
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    const i = instances.indexOf(this);
    instances.splice(i, 1);

    this.resizeObserver?.disconnect();

    this._destructors.forEach((d) => d());
  }

  public async setSize() {
    const el = this.elementRef.nativeElement;

    el.style.minHeight = el.style.maxHeight = '';

    // Delay until next event loop so size has time to update
    await new Promise((res) => setTimeout(res));

    const container = el.parentElement?.closest('.mat-mdc-tab-body-content, formly-form');
    if (!container) return; // Not part of form or has no parent

    if (container.tagName !== 'FORMLY-FORM') {
      // Has parent, delay so parent has time to resize
      await new Promise((res) => setTimeout(res, 1));
    }

    const currentElHeight = el.getBoundingClientRect().height;
    const containerHeight = container.getBoundingClientRect().height;

    const containerChildrenHeights = [...container.children].map((c) => c.getBoundingClientRect().height);
    const containerChildHeightSum = containerChildrenHeights.reduce((a, b) => a + b, 0);

    const unusedHeight = containerHeight - containerChildHeightSum;

    el.style.minHeight = el.style.maxHeight = `${currentElHeight + unusedHeight}px`;

    if (el.style.maxHeight === '0px') {
      el.style.minHeight = el.style.maxHeight = '100%';
    }
  }

  realignInkBars(): void {
    for (const i of instances) {
      try {
        i?.tabGroup?.realignInkBar();
      } catch (e) {
        console.warn('Failed to realign tab ink bar', e);
      }
    }
  }
}
