import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { RcgDirectivesModule } from '@rcg/standalone/directives';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { NotificationComponent, NotificationIconComponent, NotificationListComponent } from './components';

@NgModule({
  declarations: [NotificationComponent, NotificationIconComponent, NotificationListComponent],
  exports: [NotificationComponent, NotificationIconComponent],
  imports: [
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    RcgDirectivesModule,
    ScrollingModule,
    ToastModule,
    IntlModule,
  ],
})
export class NotificationModule {}
