import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DescriptionSetting, RcgFieldType, SelectOption } from '@rcg/core/models';
import { ContactEditDialogComponent, ContactEditDialogData } from '@rcg/forms';
import { OrganizationsDetailDialogComponent } from '@rcg/forms/components/organizations-detail-dialog/organizations-detail-dialog.component';
import { FieldAction } from '@rcg/forms/models/field-action';
import { FieldsActionsService } from '@rcg/forms/services/fields-actions.service';
import { deepDistinctUntilChanged } from '@rcg/standalone/rxjs-operators/deep-distinct-until-changed';
import { MessageService } from '@rcg/standalone/services';
import { Subscription, catchError, debounceTime, distinctUntilChanged, map, of, skip, startWith, switchMap, tap } from 'rxjs';
import { focusNextFormField } from '../../../utils/focus-utils';
import { ACSettings } from '../models/ac-settings';
import { AutocompleteService } from '../services/ac.service';
import { AcDependentFieldsHelper } from '../utils/ac-utils';

@Component({
  selector: 'rcg-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AutocompleteService],
})
export class AutocompleteFieldComponent extends RcgFieldType implements OnInit, AfterViewInit, OnDestroy {
  acSettings!: ACSettings;
  errorMessage: string | null = null;
  optionsData: SelectOption[] = [];
  isLoading = false;
  assignSelfWarn = false;

  private autocompleteSubscription?: Subscription;
  private dependentFieldSubscription?: Subscription;

  private dependentFieldsHelper!: AcDependentFieldsHelper;

  private _formElement?: Element | null;
  private _nextFocusValueSub?: Subscription;

  get formCtrl() {
    return this.formControl as UntypedFormControl & { markForChangeCheck: ChangeDetectorRef['markForCheck'] };
  }

  get showNoData(): boolean {
    const val = this.formCtrl?.value;
    return !this.isLoading && this.optionsData.length === 0 && val && typeof val === 'string' && val.length > 0;
  }

  actions: FieldAction[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    private acService: AutocompleteService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private fieldsActionsService: FieldsActionsService,
  ) {
    super();
  }

  ngOnInit() {
    this.formCtrl.markForChangeCheck = () => this.changeDetector.markForCheck();

    this.autocompleteSubscription?.unsubscribe();
    this.dependentFieldSubscription?.unsubscribe();

    try {
      this.acSettings = new ACSettings(this.formState, this.field.key!, this.props?.['settings']);
    } catch (error) {
      this.errorMessage = 'Napačni autocomplete field settings: ' + (error as Error)?.message ?? '';
      this.changeDetector.markForCheck();
      return;
    }

    if (this.acSettings.sortByLabelAsc === true && this.acSettings.sortByLabelDesc === true) {
      this.errorMessage =
        'Napačni autocomplete field setting. Nastavljena sta dva sorta: sortByLabelDesc in sortByLabelAsc. Odstranite asc ali desc sort';
      this.changeDetector.markForCheck();
      return;
    }

    this.value = this.acSettings.dontSetInitalValue === true ? null : this.getInitalValue(this.acSettings.fieldName);

    if (this.props?.readonly === true || this.props?.disabled === true) {
      return;
    }

    if (!this.acSettings.query) {
      console.error(`Wrong autocomplete settings for form field ${this.field?.key}. No query provided.`);

      this.errorMessage = 'Wrong autocomplete settings';
      this.changeDetector.markForCheck();
      return;
    }

    this.actions = this.acSettings.actions;

    this.dependentFieldsHelper = new AcDependentFieldsHelper(this.acSettings);
    if (this.dependentFieldsHelper.IsclearOndepFieldsSet) {
      this.dependentFieldsHelper.subscribeToClearOnDependentFieldsChange(this.form, this.field.key as string, (clearValue) => {
        if (clearValue) {
          this.formCtrl.value === '' ? this.formCtrl.setValue(null) : this.formCtrl.setValue('');
        }
        this.optionsData = [];
        this.changeDetector.markForCheck();
      });
    }

    this.subscribeToSearch();
  }

  ngAfterViewInit(): void {
    this._formElement = this.elementRef.nativeElement.closest('formly-form');

    this._nextFocusValueSub = this.formCtrl.valueChanges
      .pipe(startWith(this.formCtrl.value), deepDistinctUntilChanged(), skip(1))
      .subscribe((val) => {
        if (typeof val !== 'string' && val) {
          focusNextFormField(this._formElement!, this.elementRef.nativeElement);
        }
      });
  }

  override ngOnDestroy(): void {
    this.autocompleteSubscription?.unsubscribe();
    this.dependentFieldSubscription?.unsubscribe();
    this._nextFocusValueSub?.unsubscribe();
    this.dependentFieldsHelper?.unsubscribe();
  }

  dataDisplayFn(item: { label?: string }) {
    return item && item?.label ? item.label : (null as unknown as string);
  }

  clear() {
    this.assignSelfWarn = false;
    this.optionsData = [];
    this.value = null;
    this.formCtrl.markAsDirty();

    this.markFieldsForChangeCheck();
  }

  async assignSelf() {
    try {
      const result = await this.acService.assignSelf(this.acSettings, this.model);
      if (result && result.length > 0) {
        this.value = result[0];
        this.formCtrl.markAsDirty();
      } else {
        this.assignSelfWarn = true;
        this.changeDetector.detectChanges();

        setTimeout(() => {
          this.assignSelfWarn = false;
        }, 100);
      }
    } catch (error) {
      this.messageService.showErrorSnackbar('Napaka assign self', error);
    }
  }

  addNewContact() {
    if (!this.acSettings?.addContact) return;
    this.acService.addNewContact(this.acSettings.addContact, this.model, (data) => {
      const id = data?.id;
      if (!(typeof id === 'number')) return;

      this.value = this.acService.initalValueToSelectOption(this.acSettings, data, this.model);
      this.formCtrl.markAsDirty();
    });
  }

  openEditDialog() {
    switch (this.acSettings?.editButton) {
      case 'contact':
        this.dialog.open(ContactEditDialogComponent, {
          minWidth: '300px',
          width: '600px',
          maxWidth: '80vw',
          data: {
            id: (this.formControl.value as { id?: number })?.id,
          } as ContactEditDialogData,
        });
        break;
      case 'organization':
        this.dialog.open(OrganizationsDetailDialogComponent, {
          width: '0',
          height: '0',
          minWidth: '60vw',
          minHeight: '60vh',
          data: {
            id: (this.formControl.value as { id?: number })?.id,
          },
        });

        break;
      default:
        this.messageService.showWarningSnackbar(
          'Napaka',
          `Na polju je nastavljen neveljaven tip urejevalnika: ${this.acSettings?.editButton}`,
        );

        break;
    }
  }

  private getInitalValue(fieldName: string): SelectOption | null {
    const fieldData = this.model[fieldName] ?? this.model[this.field.key!];

    const dataFields: string[] | undefined = this.options.formState?.data_field?.fields;
    const isJsonDataField = dataFields && Array.isArray(dataFields) && dataFields.findIndex((i) => i === this.field.key) !== -1;

    const select = this.acService.initalValueToSelectOption(this.acSettings, fieldData, this.model, isJsonDataField);

    return select?.value ? select : null;
  }

  private subscribeToSearch() {
    this.autocompleteSubscription = this.formCtrl.valueChanges
      .pipe(
        startWith(this.value),
        debounceTime(this.acSettings.searchSettings.searchDebounceTime),
        distinctUntilChanged(),
        tap(() => {
          this.errorMessage = null;
          this.isLoading = true;
          this.changeDetector.markForCheck();
          this.markFieldsForChangeCheck();
        }),
        map((value) => {
          if (value === null || value === undefined) return '';
          if (typeof value === 'string') return value;
          if (typeof value !== 'object' || !value) return '';
          if (!('label' in value) || typeof value.label !== 'string') return '';

          return null;
        }),
        switchMap((search) => {
          if (typeof search !== 'string' && !search) return of([]);
          if (search === '' && this.acSettings.ignoreEmptySearch) return of([]);

          // dont get data from server when not all dependent fields have values
          if (this.dependentFieldsHelper.IsclearOndepFieldsSet) {
            if (!this.dependentFieldsHelper.allowSearch(this.model)) {
              this.optionsData = [];
              this.changeDetector.markForCheck();
              return of([]);
            }
          }

          return this.acService.getAutoCompleteData(this.acSettings, this.model, search).pipe(
            catchError((err) => {
              this.errorMessage = err?.message ?? err.toString() ?? 'Napaka';
              this.optionsData = [];
              this.isLoading = false;
              this.changeDetector.markForCheck();
              return of([]);
            }),
          );
        }),
      )
      .subscribe({
        next: (data) => {
          this.optionsData = data;
          this.isLoading = false;
          this.changeDetector.markForCheck();
        },
      });
  }

  private markFieldsForChangeCheck() {
    for (const c of Object.values(this.form.controls)) {
      try {
        c?.markForChangeCheck?.();
      } catch (_) {
        // Ignore
      }
    }
  }

  descriptionsArray(item: DescriptionSetting[] | string | undefined): DescriptionSetting[] {
    return item as DescriptionSetting[];
  }

  isArray(item: DescriptionSetting[] | string | undefined): boolean {
    return Array.isArray(item);
  }

  isString(item: DescriptionSetting[] | string | undefined): boolean {
    return typeof item === 'string';
  }

  async executeAction(action: FieldAction) {
    await this.fieldsActionsService.exececuteFieldAction(action, this.model, this.field, this.formCtrl.value, this.form, (input) => {
      try {
        action.afterExecuteAction(input);
        this.formCtrl.markAsDirty();
        this.changeDetector.markForCheck();
      } catch (error) {
        this.messageService.showErrorSnackbar(
          'Error form field action after submit',
          (error as { message: string | undefined })?.message ?? 'Unknown error',
        );
      }
    });
  }
}
