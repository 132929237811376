<div #container class="datetime-container flex-row">
  <div class="datetime-field-container">
    <mat-form-field
      class="datetime-input-double-form-field"
      [ngStyle]="{ minWidth: this.defaultWidthDate, maxWidth: this.defaultWidthDate, paddingRight: '8px' }"
    >
      <mat-label *ngIf="autoOptions.labels?.fromLabel">{{ autoOptions.labels!.fromLabel }}</mat-label>
      <input
        #fromDateInput
        matInput
        [matDatepicker]="pickerFrom"
        [formControl]="fromDateFC"
        (dateChange)="onFromDateChange()"
        (keyup)="onFromDateKeyup($event)"
        [errorStateMatcher]="fromDateErrMatcher"
        [readonly]="props.readonly"
      />
      <mat-datepicker-toggle matIconSuffix [for]="pickerFrom" [disabled]="props.readonly || props.disabled"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      class="datetime-input-double-form-field"
      [ngStyle]="{ minWidth: this.defaultWidthTime, maxWidth: this.defaultWidthTime }"
    >
      <mat-label *ngIf="autoOptions.labels?.fromLabel"></mat-label>
      <input
        #fromInput
        type="text"
        matInput
        [matAutocomplete]="fromAuto"
        (keyup)="onFromKeyup($event); trigger.closePanel()"
        [formControl]="fromFormControl"
        #trigger="matAutocompleteTrigger"
        [errorStateMatcher]="fromTimeErrMatcher"
        [readonly]="props.readonly"
        (click)="onTimeInputFocus($event)"
      />
      <mat-autocomplete #fromAutocomplete #fromAuto="matAutocomplete" (optionSelected)="onFromSelectChange($event)" panelWidth="auto">
        <mat-option *ngFor="let time of fromOptions" [value]="time.value">
          {{ time.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="datetime-field-container">
    <mat-form-field
      class="datetime-input-double-form-field"
      [ngStyle]="{ minWidth: this.defaultWidthDate, maxWidth: this.defaultWidthDate, paddingRight: '8px' }"
    >
      <mat-label *ngIf="autoOptions.labels?.toLabel">{{ autoOptions.labels!.toLabel }}</mat-label>
      <input
        #toDateInput
        matInput
        [matDatepicker]="pickerTo"
        [formControl]="toDateFC"
        (dateChange)="onToDateChange()"
        (keyup)="onToDateKeyup($event)"
        [errorStateMatcher]="toTimeErrMatcher"
        [readonly]="props.readonly"
      />
      <mat-datepicker-toggle matIconSuffix [for]="pickerTo" [disabled]="props.readonly || props.disabled"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      class="datetime-input-double-form-field"
      [ngStyle]="{ minWidth: this.defaultWidthTime, maxWidth: this.defaultWidthTime }"
    >
      <mat-label *ngIf="autoOptions.labels?.toLabel"></mat-label>

      <input
        #toInput
        type="text"
        matInput
        [matAutocomplete]="toAuto"
        (keyup)="onToKeyup($event); t.closePanel()"
        [formControl]="toFormControl"
        #t="matAutocompleteTrigger"
        [readonly]="props.readonly"
        (click)="onTimeInputFocus($event)"
      />
      <mat-autocomplete #toAutocomplete #toAuto="matAutocomplete" (optionSelected)="onToSelectChange()" panelWidth="auto">
        <mat-option *ngFor="let time of toOptions" [value]="time.value">
          {{ time.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="datetime-field-container">
    <mat-form-field
      *ngIf="autoOptions.displayWorkingHours"
      class="datetime-input-double-form-field"
      [ngStyle]="{ minWidth: this.defaultWidthTime, maxWidth: this.defaultWidthTime }"
    >
      <mat-label *ngIf="autoOptions.labels?.workingHoursLabel">{{ autoOptions.labels!.workingHoursLabel }}</mat-label>
      <input matInput [ngModel]="getWorkingHours()" [formControl]="workingHoursFC" readonly />
    </mat-form-field>
  </div>
</div>
