<div class="stack" [style.width.px]="qrSize()" [style.height.px]="qrSize()">
  <canvas #qrCode [width]="qrSize()" [height]="qrSize()" [style.visibility]="hasQrSettings() ? 'visible' : 'hidden'"></canvas>

  <mat-spinner *ngIf="!hasQrSettings()" mode="indeterminate" [diameter]="64"></mat-spinner>
</div>

<div class="bottom-container">
  <div #bottom class="bottom">
    <h3 class="secret">{{ 'mfa_totp_prop_secret' | intl }}:<br />{{ propsFormModel().secret ?? '...' }}</h3>

    <mat-expansion-panel style="width: calc(100% - 16px)">
      <mat-expansion-panel-header>Dodatne nastavitve</mat-expansion-panel-header>

      <!-- ? Padding -->
      <div style="height: 16px"></div>

      <div class="stack" style="width: 100%">
        <formly-form
          style="width: 100%"
          [style.opacity]="loading() ? '0.5' : '1'"
          [fields]="propsFormFields"
          [model]="propsFormModel()"
          (modelChange)="setProps.set($event)"
        ></formly-form>

        <mat-spinner *ngIf="loading()" mode="indeterminate" [diameter]="64"></mat-spinner>
      </div>
    </mat-expansion-panel>

    <span class="instructions">
      {{ 'mfa_totp_instructions' | intl }}
    </span>

    <rcg-otp-pin-input #pinInput [numDigits]="numDigits" [disabled]="loading" (codeEntered)="enteredCode.set($event)"></rcg-otp-pin-input>
  </div>
</div>
