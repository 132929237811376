import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { RcgFieldType } from '@rcg/core/models';
import { IntlService } from '@rcg/intl';

@Component({
  selector: 'rcg-datetime-picker-field',
  templateUrl: './datetime-picker-field.component.html',
  styleUrls: ['./datetime-picker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class DatetimePickerFieldComponent extends RcgFieldType<Date | string> implements OnInit {
  public readonly sfLocale$ = inject(IntlService).syncfusionLocale$;

  firstDayOfWeek = 1;

  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit() {
    // set default value if set in foems settings
    if (this.formState.query_variable && !this.model?.[this.formState.query_variable] && this.field.defaultValue) {
      const defaultValue = typeof this.field.defaultValue === 'function' ? this.field.defaultValue() : this.field.defaultValue;

      const dt = new Date(defaultValue);
      if (!dt || dt.toString() === 'Invalid Date') return;
      this.value = dt;
    }
  }

  popupOpen(e: { popup: { element: HTMLElement } }) {
    let value = this.value;

    if (!(value instanceof Date)) {
      if (typeof value !== 'string' || !value) return;

      value = new Date(value);
      if (value.toString() === 'Invalid Date') return;
    }

    try {
      const el = e.popup.element;
      if (el.classList.contains('e-datepicker')) return;

      const h = value.getHours();
      let m = value.getMinutes();
      m = (Math.round(m / 30) * 30) % 60;

      const ph = h.toString().padStart(2, '0');
      const pm = m.toString().padStart(2, '0');

      const timeEl = el.querySelector(`li[data-value="${ph}:${pm}"]`);
      timeEl?.scrollIntoView({ behavior: 'instant', block: 'center' });
    } catch (e) {
      console.warn('Failed to scroll datetime selected time into view:', e);
    }
  }
}
