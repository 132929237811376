import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/material/form-field';

export class TimeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid);
  }
}

export interface TimeFieldSettings extends FormlyFieldProps {
  format: string;
}

@Component({
  selector: 'rcg-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss'],
})
export class TimeFieldComponent extends FieldType<FieldTypeConfig<TimeFieldSettings>> implements OnInit {
  constructor() {
    super();
  }
  time: Date | null = null;

  ngOnInit() {
    this.time = new Date(Date.parse(`2000-01-01T${this.value}`));
  }

  onChange(val: Date) {
    const newTime = val.toTimeString().split(' ')[0];
    this.formControl.setValue(newTime);
    this.formControl.markAsDirty();
  }
}
