<mat-stepper [linear]="false">
  <mat-step [completed]="false" *ngFor="let step of field.fieldGroup; let index = index; let last = last">
    <ng-template matStepLabel>{{ step?.props?.label ?? 'Korak' }}</ng-template>

    <formly-field [field]="step"></formly-field>

    <div>
      <button matStepperPrevious *ngIf="index !== 0" mat-button color="primary">{{ 'previus' | intl }}</button>

      <button matStepperNext *ngIf="!last" mat-button color="primary" [disabled]="!isValid(step)">{{ 'next' | intl }}</button>
    </div>
  </mat-step>
</mat-stepper>
