import { DescriptionSetting, SelectColor, SelectOption } from '@rcg/core/models';
import { FieldAction } from '../../../models/field-action';

export class AcSearchSettings {
  prefix: string;
  searchDebounceTime: number;
  suffix: string;
  variable: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(searchSettings: Record<string, any> | undefined) {
    this.prefix = searchSettings?.prefix ?? '';
    this.searchDebounceTime = searchSettings?.searchDebounceTime ?? 600;
    this.suffix = searchSettings?.suffix ?? '';
    this.variable = searchSettings?.variable ?? 'search';
  }
}

export class AddNewContactSettings {
  addNewFormName?: string | null;
  addNewIcon?: string | null;
  addNewDialogTitle?: string | null;
  addNewPrefillData?: Record<string, unknown> | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(settings: Record<string, any> | undefined) {
    this.addNewFormName = settings?.addNewFormName ?? null;
    this.addNewIcon = settings?.addNewIcon ?? null;
    this.addNewDialogTitle = settings?.addNewDialogTitle ?? null;
    this.addNewPrefillData = settings?.addNewPrefillData ?? null;
  }
}

abstract class AcBaseSettings {
  query: string;
  fieldName: string;
  searchSettings: AcSearchSettings;
  gqlVariables?: Record<string, unknown> | null;
  gqlVariablesFromModel: Record<string, string | null> | null;
  gqlVariablesFromModelAssignSelf: Record<string, string> | null;
  initalValueFromModel: {
    label?: string;
    value?: string;
    data?: string;
  };
  searchValue?: {
    label?: string;
    value?: string;
    data?: string;
  };
  additionalDataFields?: string[];
  prefilledGqlVariables?: Record<string, unknown>;
  dontSetInitalValue?: boolean;
  ignoreEmptySearch: boolean;
  clearOnDependentFieldsChange: string[] | undefined;
  allowSearchOnEmptyDependentFields: string[] | undefined;
  sortByLabelAsc: boolean | undefined;
  sortByLabelDesc: boolean | undefined;
  allowHintValues: boolean;
  color: SelectColor | undefined;
  descriptionSetting: (string | { literal: string })[] | ((value: Record<string, unknown>) => string | DescriptionSetting[]);
  preventOpenDetailDialog?: boolean;
  actions: FieldAction[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(formState: any, formFieldKey: string, settings: Record<string, any> | undefined) {
    this.query = settings!.query;
    this.fieldName = settings?.field ?? '';
    this.searchSettings = new AcSearchSettings(settings?.search);
    this.gqlVariables = settings?.gqlVariables ?? null;
    this.gqlVariablesFromModel = settings?.gqlVariablesFromModel ?? null;
    this.gqlVariablesFromModelAssignSelf = settings?.gqlVariablesFromModelAssignSelf ?? null;
    this.initalValueFromModel = settings?.initalValueFromModel ?? null;
    this.searchValue = settings?.searchValue ?? null;
    this.additionalDataFields = settings?.additionalDataFields;
    this.prefilledGqlVariables =
      formState?.prefilledGqlVariables && formState.prefilledGqlVariables[formFieldKey]
        ? formState.prefilledGqlVariables[formFieldKey]
        : null;
    this.dontSetInitalValue = settings?.dontSetInitalValue === true ? true : false;
    this.ignoreEmptySearch = settings?.ignoreEmptySearch === true;
    this.clearOnDependentFieldsChange = settings?.clearOnDependentFieldsChange;
    this.allowSearchOnEmptyDependentFields = settings?.allowSearchOnEmptyDependentFields;
    this.sortByLabelAsc = settings?.sortByLabelAsc ?? false;
    this.sortByLabelDesc = settings?.sortByLabelDesc ?? false;
    this.allowHintValues = settings?.allowHintValues ?? false;
    this.color =
      settings?.color?.textColor ||
      settings?.color?.backgroundColor ||
      settings?.color?.fixedTextColor ||
      settings?.color?.fixedBackgroundColor
        ? {
            textColor: settings?.color?.textColor,
            backgroundColor: settings?.color?.backgroundColor,
            fixedTextColor: settings?.color?.fixedTextColor,
            fixedBackgroundColor: settings?.color?.fixedBackgroundColor,
          }
        : undefined;
    this.descriptionSetting = settings?.description;
    this.preventOpenDetailDialog = settings?.preventOpenDetailDialog ?? false;
    this.actions = settings?.actions ?? [];
  }
}

export class AcTreeSettings extends AcBaseSettings {
  selectableGroup: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(formState: any, formFieldKey: string, settings: Record<string, any> | undefined) {
    super(formState, formFieldKey, settings);
    this.selectableGroup = settings?.selectableGroup ?? false;
    if (!settings?.query) {
      throw new Error('Query is required for autocomplete tree field');
    }
  }
}

export class ACSettings extends AcBaseSettings {
  labelNames: (string | { literal: string })[];
  valueName: string;
  resultDataName: string;
  assignSelfQuery?: string | null;
  addContact?: AddNewContactSettings | null;
  editButton?: 'contact' | 'organization';
  selectedLabel?: string | null; // multiselect
  prefillDependentFildsOnSelected?: Record<string, string>; // hint
  clearChipsOnEmptyFormFieldValue?: boolean;
  chipsTooltip?: (selectValue: SelectOption) => string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(formState: any, formFieldKey: string, settings: Record<string, any> | undefined) {
    super(formState, formFieldKey, settings);

    this.labelNames = settings?.label ?? [];
    this.valueName = settings?.value ?? '';
    this.resultDataName = settings?.result ?? '';
    this.assignSelfQuery = settings?.assignSelfQuery ?? null;
    this.addContact = new AddNewContactSettings(settings);
    this.editButton = settings?.editButton;
    this.selectedLabel = settings?.selectedLabel ?? null;
    if (settings?.prefillDependentFildsOnSelected && Object.keys(settings?.prefillDependentFildsOnSelected).length > 0) {
      this.prefillDependentFildsOnSelected = settings!.prefillDependentFildsOnSelected;
    }
    this.clearChipsOnEmptyFormFieldValue = settings?.clearChipsOnEmptyFormFieldValue ?? false;
    this.chipsTooltip = settings?.chipsTooltip;
  }
}
