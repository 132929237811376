import { gql } from '@apollo/client/core';

export const initializedUserMfaSubscription = gql`
  subscription UserMfa {
    data: identity_user_mfa(where: { initialized: { _eq: true } }, order_by: { created_at: asc }) {
      id
      type
      created_at
      description
    }
  }
`;
