import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rcg-organizations-detail-dialog',
  templateUrl: './organizations-detail-dialog.component.html',
  styleUrls: ['./organizations-detail-dialog.component.scss'],
})
export class OrganizationsDetailDialogComponent {
  constructor(public dialogRef: MatDialogRef<OrganizationsDetailDialogComponent>) {}
}
