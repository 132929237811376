<ng-container *ngIf="errorMessage">
  <div class="error-message">{{ 'error' | intl }}: {{ errorMessage }}</div>
</ng-container>

<div class="chips">
  <mat-chip-grid #chipList aria-label="Item selection">
    <mat-chip-row
      [disabled]="props.readonly === true || props.disabled === true"
      *ngFor="let item of selectedOptions"
      [removable]="removable"
      (removed)="remove(item)"
      (click)="badgeActionClick(item)"
    >
      <div [matTooltip]="item | chipsTooltip : acSettings">{{ item.label }}</div>
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input
      #input
      [id]="id"
      type="text"
      [formControl]="inputFormControl"
      [formlyAttributes]="field"
      [required]="props.required ?? false"
      [placeholder]="props.placeholder ?? ''"
      [tabIndex]="props.tabindex"
      [readonly]="props.readonly === true || props.disabled === true"
      [matAutocomplete]="autocomplete"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <div>
    <mat-icon *ngIf="acSettings?.addContact?.addNewFormName" class="field-icon-btn" (click)="addNewContact()">person_add</mat-icon>
    @for(action of actions | visibleFieldActions: value:field;track $index){
    <mat-icon [matTooltip]="(action.tooltip | tooltip | async) ?? ''" class="field-icon-btn" (click)="executeAction(action)">{{
      action.icon
    }}</mat-icon>
    }
  </div>
  <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="selected($event)" [panelWidth]="'auto'">
    <mat-option *ngIf="inputFormControl?.value | showNoData : isLoading : autocompleteOptions">
      <span
        style="color: #6499e9"
        *ngIf="allowHintValues | validInputValue : inputFormControl?.value : selectedOptions : validatorRegex as inputValue"
      >
        {{ validHintName }}:
        {{ inputValue }}
      </span>
      <div style="color: rgba(black, 0.38)">{{ 'no_results' | intl }}</div>
    </mat-option>
    <ng-container *ngIf="!(inputFormControl?.value | showNoData : isLoading : autocompleteOptions)">
      <mat-option *ngFor="let item of autocompleteOptions" [value]="item">
        {{ item.label }}
        <ng-container *ngIf="item.description">
          <br />
          <span style="color: gray; font-size: 12px">{{ item.description }}</span>
        </ng-container>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
