import { DocumentNode, OperationVariables, gql } from '@apollo/client/core';
import { OrganizationShareType, RcgTenant } from '@rcg/auth';

const orgContactsDataFragment = gql`
  fragment orgContactsData on global_contacts {
    id
    first_name
    last_name
    email
    telephone
    data
  }
`;

const orgContactsSubscription_all = gql`
  ${orgContactsDataFragment}
  subscription orgContacts($employerId: bigint!, $orgId: bigint!, $search: String = "") {
    data: global_search_contacts(
      args: { org_id: $orgId, search: $search }
      where: { _and: [{ employer_id: { _eq: $employerId } }, { organization_id: { _eq: $orgId } }] }
      order_by: [{ first_name: asc }, { last_name: asc }]
    ) {
      ...orgContactsData
    }
  }
`;

const orgContactsSubscription_organization = orgContactsSubscription_all;

const orgContactsSubscription_tenant = gql`
  ${orgContactsDataFragment}
  subscription orgContacts($employerId: bigint!, $orgId: bigint!, $tenantId: bigint!, $search: String = "") {
    data: global_search_contacts(
      args: { org_id: $orgId, search: $search }
      where: { _and: [{ employer_id: { _eq: $employerId } }, { organization_id: { _eq: $orgId } }, { tenant_id: { _eq: $tenantId } }] }
      order_by: [{ first_name: asc }, { last_name: asc }]
    ) {
      ...orgContactsData
    }
  }
`;

export const organizationContactsSubscription: {
  [ost in OrganizationShareType]: DocumentNode;
} = {
  [OrganizationShareType.all]: orgContactsSubscription_all,
  [OrganizationShareType.organization]: orgContactsSubscription_organization,
  [OrganizationShareType.tenant]: orgContactsSubscription_tenant,
};

export function organizationContactsSubscriptionVariables(employerId: number, tenant: RcgTenant, search?: string): OperationVariables {
  const vars: OperationVariables = {
    employerId,
    orgId: tenant.organization.id,
    search,
  };

  if (tenant.organizationShareType === OrganizationShareType.tenant) {
    vars.tenantId = tenant.id;
  }

  return vars;
}

export const organizationChildren = gql`
  query organizationChildrenQuery(
    $limit: Int
    $offset: Int
    $id: bigint!
    $org_share_type: Int!
    $org_id: bigint!
    $tenant_id: bigint!
    $search: String!
  ) {
    data: global_search_organizations_2(
      args: { p_org_share_type: $org_share_type, p_org_id: $org_id, p_tenant_id: $tenant_id, p_search: $search }
      limit: $limit
      offset: $offset
      where: { parent_id: { _eq: $id } }
      order_by: { owner_id: asc_nulls_last, short_name: asc }
    ) {
      id
      short_name
      long_name
      postal_name
      street
      postal_number
      country {
        name
      }
    }
  }
`;

export const countOrganizationChildren = gql`
  query countOrganizationChildrenQuery($id: bigint!, $org_share_type: Int!, $org_id: bigint!, $tenant_id: bigint!, $search: String!) {
    data: global_search_organizations_2_aggregate(
      args: { p_org_share_type: $org_share_type, p_org_id: $org_id, p_tenant_id: $tenant_id, p_search: $search }
      where: { parent_id: { _eq: $id } }
      order_by: { owner_id: asc_nulls_last, short_name: asc }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const InstalledEquipmentQuery = gql`
  query installedEquipment($tenant_id: bigint!, $orgId: bigint!, $limit: Int, $offset: Int) {
    data: custom_installed_equipment(
      where: { _and: { tenant_id: { _eq: $tenant_id }, customer_id: { _eq: $orgId } } }
      offset: $offset
      limit: $limit
    ) {
      id
      product_id
      serial_no
      description
      form_id
    }
  }
`;

export const countInstalledEquipmentQuery = gql`
  query countInstalledEquipment($tenant_id: bigint!, $orgId: bigint!, $limit: Int, $offset: Int) {
    data: custom_installed_equipment_aggregate(where: { _and: { tenant_id: { _eq: $tenant_id }, customer_id: { _eq: $orgId } } }) {
      aggregate {
        count
      }
    }
  }
`;
