import { Pipe, PipeTransform } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldAction } from '../models/field-action';

@Pipe({
  name: 'visibleFieldActions',
  pure: true,
})
export class VisibleFieldActionsPipe implements PipeTransform {
  transform(actions: FieldAction[] | undefined, fieldValue: unknown, field: FormlyFieldConfig): FieldAction[] {
    if (!actions?.length) return [];
    return actions.filter((a) => (a.visible ? (typeof a.visible === 'function' ? a.visible(fieldValue, field) : a.visible) : false));
  }
}
