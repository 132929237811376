import { ChangeDetectionStrategy, Component, OnInit, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, map, startWith } from 'rxjs';
import { searchIconsGql } from './icon-picker-field.gql';

export interface Icon {
  id: string;
  name: string;
}

export interface IconPickerFieldProps extends FormlyFieldProps {}

export type IconPickerFieldConfig = FieldTypeConfig<IconPickerFieldProps>;

@Component({
  selector: 'rcg-icon-picker-field',
  templateUrl: './icon-picker-field.component.html',
  styleUrls: ['./icon-picker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPickerFieldComponent extends FieldType<IconPickerFieldConfig> implements OnInit {
  iconOptions = toSignal(this.getIcons());

  filteredIconOptions: Observable<Icon[]> | undefined;
  height: string = '0px';

  constructor(private graphQlService: GraphqlClientService) {
    effect(() => {
      const icons = this.iconOptions();
      if (icons) {
        this.filteredIconOptions = this.formControl.valueChanges.pipe(
          startWith(this.value),
          map((value) => this.filterIcons(value || '', icons)),
        );
      }
    });
    super();
  }

  ngOnInit() {
    this.formControl.addValidators([
      (control: AbstractControl): ValidationErrors | null => {
        const valid = (this.iconOptions() ?? []).filter((el) => el.id === control.value).length === 1;
        return valid ? null : { notAnIcon: control.value };
      },
    ]);
  }

  getIcons() {
    return this.graphQlService
      .query<{ data?: Icon[] }>({
        query: searchIconsGql,
        variables: { search: '%' + '' + '%' },
      })
      .pipe(
        map((data) => {
          return data?.data ?? [];
        }),
      );
  }

  private filterIcons(value: string, icons: Icon[]): Icon[] {
    const filterValue = value.toLowerCase();
    if (icons) {
      const filtered = icons.filter(
        (option) => option.id.toLowerCase().includes(filterValue) || option.name.toLocaleLowerCase().includes(filterValue),
      );

      if (filtered.length < 4) {
        this.height = filtered.length * 50 + 'px';
      } else {
        this.height = '200px';
      }

      return filtered;
    } else {
      return [];
    }
  }
}
