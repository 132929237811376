<mat-form-field class="password-change-form-field">
  <input
    matInput
    #pass1
    [type]="hide ? 'password' : 'text'"
    [formControl]="pass1FC"
    [errorStateMatcher]="passErrMatcher"
    (keyup)="onPass1Keyup()"
  />
  <mat-icon
    matSuffix
    (click)="hide = !hide"
    [style.pointer-events]="formControl.enabled ? '' : 'none'"
    [style.opacity]="formControl.enabled ? '1' : '0.24'"
    >{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon
  >
</mat-form-field>

<mat-form-field class="password-change-form-field">
  <input
    matInput
    #pass2
    [type]="hide ? 'password' : 'text'"
    [formControl]="pass2FC"
    [errorStateMatcher]="passErrMatcher"
    (keyup)="onPass2Keyup()"
  />
  <mat-icon
    matSuffix
    (click)="hide = !hide"
    [style.pointer-events]="formControl.enabled ? '' : 'none'"
    [style.opacity]="formControl.enabled ? '1' : '0.24'"
    >{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon
  >
</mat-form-field>
