import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RcgFieldType } from '@rcg/core/models';

@Component({
  selector: 'rcg-rating-field',
  templateUrl: './rating-field.component.html',
  styleUrls: ['./rating-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingFieldComponent extends RcgFieldType implements OnInit {
  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit() {
    this.value = this.value ?? null;
  }

  override get shouldLabelFloat() {
    return true;
  }
}
