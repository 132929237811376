<rcg-dialog
  [iconTooltip]="formConfig.formId ? 'Form: ' + formConfig.formId : undefined"
  [dialogRef]="dialogRef"
  icon="edit"
  [title]="data.id ? ('editing_contact' | intl) : ('adding_contact' | intl)"
>
  <div class="wrapper">
    <rcg-formly-form [formConfig]="formConfig" (submitSuccess)="submitSuccess($event)"></rcg-formly-form>
  </div>
</rcg-dialog>
