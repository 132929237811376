<rcg-time-spent
  #timeField
  [id]="id"
  [required]="props.required ?? false"
  [formControl]="formCtrl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder ?? ''"
>
</rcg-time-spent>
<!-- [tabIndex]="props.tabindex"  -->
