<ng-container [ngSwitch]="props.headingType">
  <h1 *ngSwitchCase="'h1'">{{value}}</h1>
  <h2 *ngSwitchCase="'h2'">{{value}}</h2>
  <h3 *ngSwitchCase="'h3'">{{value}}</h3>
  <h4 *ngSwitchCase="'h4'">{{value}}</h4>
  <h5 *ngSwitchCase="'h5'">{{value}}</h5>
  <h6 *ngSwitchCase="'h6'">{{value}}</h6>
  <pre *ngSwitchCase="'pre'">{{value}}</pre>
  <p *ngSwitchDefault>{{value}}</p>
</ng-container>
