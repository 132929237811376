@for (l of lists; track $index) {
<div class="list-container">
  <h2 class="title">{{ l.title | intl }}</h2>

  <mat-form-field class="search">
    <mat-label>{{ 'placeholder_search' | intl }}</mat-label>
    <input matInput [ngModel]="this[l.searchRKey].value$ | async" (ngModelChange)="this[l.searchRKey].data = $event" />
    @if (this[l.searchRKey].value$ | async) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="this[l.searchRKey].data = ''">
      <mat-icon>clear</mat-icon>
    </button>
    }
  </mat-form-field>

  <div class="actions" *ngIf="l.actions as actions">
    @for (action of actions; track $index) {
    <button
      mat-icon-button
      [matTooltip]="action.tooltip | intl : { default: action.tooltip + ' (n.t.)' }"
      (click)="$event.preventDefault(); this[action.exec]()"
    >
      <mat-icon>{{ action.icon }}</mat-icon>
    </button>
    }
  </div>

  <div cdkDropList #memberList="cdkDropList" [cdkDropListData]="this[l.listRKey]" class="group-list" (cdkDropListDropped)="drop($event)">
    @for (item of this[l.filteredRKey].value$ | async; track item) {
    <div class="group-list-item" cdkDrag [cdkDragDisabled]="!!item.disabled">
      <mat-list-item [style.opacity]="item.disabled ? 0.5 : undefined">
        <span matListItemTitle>{{ item.name }}</span>

        <mat-chip-listbox matListItemMeta *ngIf="item.tags?.length">
          @for (tag of item.tags; track $index) {
          <mat-chip-option
            [style.background-color]="tag.color ?? 'lightgray'"
            [style.--mdc-chip-label-text-color]="tag.textColor ?? getContrastColor(tag.color ?? 'lightgray')"
            >{{ tag.text }}</mat-chip-option
          >
          }
        </mat-chip-listbox>
      </mat-list-item>

      <mat-divider></mat-divider>
    </div>
    }
  </div>
</div>
}
