import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';
import { MessageService } from '@rcg/standalone/services';
import { FieldAction } from '../models/field-action';
import { FieldsActionsService } from '../services/fields-actions.service';

@Component({
  selector: 'rcg-field-actions-wrapper',
  templateUrl: './field-actions-wrapper.component.html',
  styleUrl: './field-actions-wrapper.component.scss',
})
export class FieldActionsWrapperComponent extends FieldWrapper implements OnInit {
  private fieldsActionsService = inject(FieldsActionsService);
  private changeDetector = inject(ChangeDetectorRef);
  private messageService = inject(MessageService);

  actions: FieldAction[] = [];

  ngOnInit(): void {
    this.actions = this.props?.settings?.actions ?? [];
  }

  get value() {
    return this.formControl.value;
  }

  async executeAction(action: FieldAction) {
    await this.fieldsActionsService.exececuteFieldAction(
      action,
      this.model,
      this.field,
      this.formControl.value,
      this.field.form as FormGroup,
      (input) => {
        try {
          action.afterExecuteAction(input);
          this.formControl.markAsDirty();
          this.changeDetector.markForCheck();
        } catch (error) {
          this.messageService.showErrorSnackbar(
            'Error form field action after submit',
            (error as { message: string | undefined })?.message ?? 'Unknown error',
          );
        }
      },
    );
  }
}
