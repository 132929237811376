import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@rcg/core/models';

@Pipe({
  name: 'validInputValue',
  pure: true,
})
export class ValidInputValuePipe implements PipeTransform {
  transform(
    allowedHintValues: boolean,
    inputValue: string | null | undefined,
    selectedOptions: SelectOption[],
    validatorRegex: RegExp,
  ): string | null | undefined {
    if (!allowedHintValues) {
      return null;
    }
    const value = inputValue?.trim();
    if (!value) {
      return null;
    }
    const alreadySelected = selectedOptions.findIndex((s) => s.value === value) !== -1;
    if (!alreadySelected && validatorRegex.test(value) ? value : null) {
      return value;
    }
    return null;
  }
}

@Pipe({
  name: 'showNoData',
  pure: true,
})
export class ShowNoDataPipe implements PipeTransform {
  transform(inputValue: string | null | undefined, isLoading: boolean, autocompleteOptions: SelectOption[]): boolean {
    const value = inputValue?.trim();
    return !isLoading && !!value && typeof value === 'string' && value.length > 0 && autocompleteOptions.length === 0;
  }
}
