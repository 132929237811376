import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { IEditorValue, RcgFieldType } from '@rcg/core/models';
import { FormDialogService } from '../../services/form-dialog.service';

@Component({
  selector: 'rcg-text-area-field',
  templateUrl: './text-area-field.component.html',
  styleUrls: ['./text-area-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: TextAreaFieldComponent }],
})
export class TextAreaFieldComponent extends RcgFieldType<string | null | undefined> implements OnInit {
  constructor(private formDialogService: FormDialogService) {
    super();
  }

  initialValue!: IEditorValue;
  get formCtrl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit(): void {
    this.initialValue = {
      plain: this.model[this.field.key as string]?.plain ?? '',
      html: this.model[this.field.key as string]?.html ?? '',
      eml_id: this.model[this.field.key as string]?.eml_id ?? '',
    };

    this.formControl.setValue(this.initialValue?.plain ?? '');
  }

  onFocusIn() {
    if (!this.focused) {
      this.field.focus = true;
      this.stateChanges.next();
    }
  }

  onFocusOut() {
    this.field.focus = false;
    this.stateChanges.next();
  }

  openHtmlEditor() {
    this.formDialogService.openEditor({
      dialogTitle: 'Editor',
      initialValue: { ...this.initialValue, plain: this.value ?? null },
    });
  }
}
