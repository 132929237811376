<div style="width: 100%" (focusin)="onFocusIn()" (focusout)="onFocusOut()">
  <input
    matInput
    [id]="id"
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="1"
    [formControl]="formControl"
    [formlyAttributes]="field"
    type="text"
    [required]="props.required ?? false"
    [readonly]="props.readonly"
    [placeholder]="props.placeholder ?? ''"
    [tabIndex]="props.tabindex"
  />
</div>
<div *ngIf="value" class="phone-icon">
  <mat-icon [matTooltip]="'phone_number' | intl" matTooltipPosition="below" color="primary" (click)="callNumber()">call </mat-icon>
</div>
