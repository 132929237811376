import { Pipe, PipeTransform } from '@angular/core';
import { Observable, isObservable, of } from 'rxjs';

@Pipe({
  name: 'tooltip',
  pure: true,
})
export class TooltipPipe implements PipeTransform {
  transform(tooltip: string | Observable<string> | undefined | null): Observable<string> {
    if (!tooltip) return of('');
    if (typeof tooltip === 'string') return of(tooltip);
    if (isObservable(tooltip)) {
      return tooltip;
    }
    return of('');
  }
}
